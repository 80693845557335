import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCollator } from '../../hooks';
import ColumnSelector from './ColumnSelector';
import EnhancedTableHead from './TableHead';
import ToolBar from './ToolBar';

function descendingComparator(a, b, orderBy, collator) {
  if (b[orderBy] == null) {
    return -1;
  }
  if (a[orderBy] == null) {
    return 1;
  }
  return collator.compare(b[orderBy], a[orderBy]);
}

function getComparator(order, orderBy, collator) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, collator)
    : (a, b) => -descendingComparator(a, b, orderBy, collator);
}

function stableSort(array, comparator) {
  return array
    .map((el, index) => [el, index])
    .sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    .map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[6],
    borderRadius: theme.borderRadius,
    borderColor: theme.palette.borderColor,
    padding: ({ gridStyle }) => (gridStyle ? theme.spacing(1, 1, 3, 1) : theme.spacing(0, 0, 3, 0))
  },
  table: {
    minWidth: 750,
    overflow: 'hidden',
    borderStyle: 'hidden'
  },
  row: {
    transition: theme.transitionAll
  },
  cell: {
    borderBottom: 'none',
    borderColor: theme.palette.borderColor
  },
  cellGrid: {
    border: '1px solid',
    borderColor: theme.palette.borderColor
  },
  cellInner: {
    padding: theme.spacing(1, 3)
  },
  cellGridInner: {
    padding: theme.spacing(2.25, 2)
  },
  rowLink: {
    display: 'flex',
    textDecoration: 'none',
    color: 'inherit'
  },
  rowPersistent: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 500
  },
  paginationBox: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    bottom: '-20px',
    border: '1px solid',
    borderColor: theme.palette.borderColor,
    boxShadow: theme.shadows[6],
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25)
  },
  paginationToolbar: {
    minHeight: 0
  }
}));

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const handleFirstPageButtonClick = useCallback(
    (event) => {
      onChangePage(event, 0);
    },
    [onChangePage]
  );

  const handleBackButtonClick = useCallback(
    (event) => {
      onChangePage(event, page - 1);
    },
    [onChangePage, page]
  );

  const handleNextButtonClick = useCallback(
    (event) => {
      onChangePage(event, page + 1);
    },
    [onChangePage, page]
  );

  const lastPage = useMemo(() => Math.ceil(count / rowsPerPage) - 1, [count, rowsPerPage]);

  const handleLastPageButtonClick = useCallback(
    (event) => {
      onChangePage(event, Math.max(0, lastPage));
    },
    [lastPage, onChangePage]
  );

  const cantGoBack = useMemo(() => page === 0, [page]);
  const cantGoForth = useMemo(() => page >= lastPage, [lastPage, page]);

  return (
    <Box ml={1} flexShrink={0}>
      <IconButton size="small" onClick={handleFirstPageButtonClick} disabled={cantGoBack}>
        <FirstPageIcon />
      </IconButton>
      <IconButton size="small" onClick={handleBackButtonClick} disabled={cantGoBack}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton size="small" onClick={handleNextButtonClick} disabled={cantGoForth}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton size="small" onClick={handleLastPageButtonClick} disabled={cantGoForth}>
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const TableRowSkeleton = ({ selectedColumns, length = 4 }) => {
  const classes = useStyles();

  return (
    <>
      {[...Array(length)].map((e, i) => (
        <TableRow key={i}>
          {selectedColumns.map((id) => (
            <TableCell key={id} padding="none" className={classes.cell}>
              <Box p={0.5}>
                <Skeleton variant="rect" width="100%" height="50px" animation="wave" />
              </Box>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

const EmptyTable = () => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <Box p={3} pl={1} fontSize={16} fontWeight={500}>
          {t('table_no_results')}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const EnhancedTable = ({
  rows,
  headCells,
  defaultPagination,
  defaultOrderBy,
  gridStyle,
  rowLinks,
  exporter,
  searchBarFieldIds,
  title,
  columnSelector,
  defaultColumnsSelected,
  idColumn,
  persistentRowId,
  rowsPerPageOptions,
  loading,
  ...rest
}) => {
  const classes = useStyles({ gridStyle });
  const collator = useCollator();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(defaultPagination);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [searchFilterRegExp, setSearchFilterRegExp] = useState();
  const visibleHeadCells = useMemo(
    () =>
      Object.keys(headCells)
        .filter((headCellId) => !headCells[headCellId].hidden)
        .reduce((obj, key) => {
          obj[key] = headCells[key];
          return obj;
        }, {}),
    [headCells]
  );
  const [selectedColumns, setSelectedColumns] = useState(
    defaultColumnsSelected || Object.keys(visibleHeadCells)
  );

  useEffect(() => {
    setSelectedColumns(defaultColumnsSelected || Object.keys(visibleHeadCells));
    setCurrentPage(0);
  }, [visibleHeadCells, defaultColumnsSelected]);

  const onChangePage = useCallback((event, newPage) => {
    setCurrentPage(newPage);
  }, []);

  const onChangeRowsPerPage = useCallback((event) => {
    setPagination(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }, []);

  const setSorting = useCallback(
    (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setCurrentPage(0);
    },
    [order, orderBy]
  );

  const { persistentRow, nonPersistentRows } = useMemo(() => {
    const nonPersistentRows = [...rows];
    let persistentRow = null;
    if (persistentRowId) {
      const persistentRowIndex = nonPersistentRows.findIndex(
        (row) => row[idColumn] === persistentRowId
      );
      if (persistentRowIndex !== -1) {
        persistentRow = { ...nonPersistentRows.splice(persistentRowIndex, 1)[0], persistent: true };
      }
    }
    return { persistentRow, nonPersistentRows };
  }, [rows, persistentRowId, idColumn]);

  const sortedRows = useMemo(() => {
    let sorted = stableSort(nonPersistentRows, getComparator(order, orderBy, collator));
    if (searchFilterRegExp && searchBarFieldIds.length) {
      sorted = sorted.filter((row) =>
        searchBarFieldIds.some((searchBarFieldId) => searchFilterRegExp.test(row[searchBarFieldId]))
      );
    }
    return sorted;
  }, [order, orderBy, nonPersistentRows, searchFilterRegExp, searchBarFieldIds, collator]);

  const visibleRows = useMemo(
    () => sortedRows.slice(currentPage * pagination, currentPage * pagination + pagination),
    [currentPage, pagination, sortedRows]
  );

  const tableRows = useMemo(
    () =>
      [...(persistentRow ? [persistentRow] : []), ...visibleRows].map((row) => (
        <TableRow hover tabIndex={-1} key={row[idColumn]} className={classes.row}>
          {selectedColumns.map((id) => {
            if (headCells[id]) {
              const Renderer = headCells[id]?.Renderer;
              return (
                <TableCell
                  align={gridStyle ? 'center' : 'left'}
                  key={`${row[idColumn]}_${id}`}
                  padding="none"
                  className={clsx(
                    gridStyle ? classes.cellGrid : classes.cell,
                    row.persistent && classes.rowPersistent
                  )}
                >
                  <Box
                    className={clsx(
                      gridStyle ? classes.cellGridInner : classes.cellInner,
                      rowLinks && classes.rowLink
                    )}
                    {...(rowLinks
                      ? {
                          component: Link,
                          to: row.to || ''
                        }
                      : {})}
                  >
                    {Renderer ? <Renderer {...row} headId={id} /> : row[id]}
                  </Box>
                </TableCell>
              );
            }
            return null;
          })}
        </TableRow>
      )),
    [
      persistentRow,
      visibleRows,
      idColumn,
      selectedColumns,
      headCells,
      gridStyle,
      classes.row,
      classes.cellGrid,
      classes.cell,
      classes.cellGridInner,
      classes.cellInner,
      classes.rowLink,
      classes.rowPersistent,
      rowLinks
    ]
  );

  const exporterData = useMemo(
    () => [...(persistentRow ? [persistentRow] : []), ...sortedRows],
    [persistentRow, sortedRows]
  );

  return (
    <Box {...rest}>
      {(searchBarFieldIds.length || title || exporter) && (
        <ToolBar
          searchBarFieldIds={searchBarFieldIds}
          setSearchFilterRegExp={setSearchFilterRegExp}
          headCells={headCells}
          title={title}
          exporter={exporter}
          exporterData={exporterData}
          selectedColumns={selectedColumns}
        />
      )}
      {columnSelector && (
        <ColumnSelector
          headCells={visibleHeadCells}
          setSelectedColumns={setSelectedColumns}
          selectedColumns={selectedColumns}
        />
      )}
      <Box className={classes.root} border={1}>
        <TableContainer>
          <Table className={classes.table} size="medium" padding="none">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={setSorting}
              headCells={visibleHeadCells}
              selectedColumns={selectedColumns}
              gridStyle={gridStyle}
            />
            <TableBody>
              {loading ? (
                <TableRowSkeleton selectedColumns={selectedColumns} />
              ) : tableRows.length ? (
                tableRows
              ) : (
                <EmptyTable />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={clsx(classes.paginationBox, 'joyride-Pagination')}>
          <TablePagination
            component="div"
            count={nonPersistentRows.length}
            page={currentPage}
            onChangePage={onChangePage}
            rowsPerPage={pagination}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            backIconButtonProps={{ size: 'small' }}
            nextIconButtonProps={{ size: 'small' }}
            classes={{ toolbar: classes.paginationToolbar }}
            labelRowsPerPage={t('table_pagination')}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      </Box>
    </Box>
  );
};

EnhancedTable.defaultProps = {
  defaultPagination: 5,
  gridStyle: false,
  searchBarFieldIds: [],
  rowsPerPageOptions: [5, 10, 20],
  loading: false
};

EnhancedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.object.isRequired,
  defaultOrderBy: PropTypes.string.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  idColumn: PropTypes.string.isRequired,
  persistentRowId: PropTypes.node,
  defaultPagination: PropTypes.number,
  gridStyle: PropTypes.bool,
  rowLinks: PropTypes.bool,
  exporter: PropTypes.bool,
  searchBarFieldIds: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.node,
  columnSelector: PropTypes.bool,
  loading: PropTypes.bool,
  defaultColumnsSelected: PropTypes.array
};

export default EnhancedTable;
