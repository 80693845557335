import { store } from '@risingstack/react-easy-state';
import { useEffect } from 'react';
import { params } from 'react-easy-params';

export default function useElectionsStore({ defaultElection, initElections, copyIndicator }) {
  const electionsStore = store({
    stores: {},
    get storesArray() {
      return Object.values(electionsStore.stores).sort(
        (a, b) => new Date(a.event.event_date) - new Date(b.event.event_date)
      );
    },
    get loading() {
      return electionsStore.storesArray.some((electionstore) => electionstore.loading);
    },
    defaultElection,
    createElection({ election }) {
      let freeId = 1;
      const ids = params.ids || [];
      [...ids]
        .sort((a, b) => a - b)
        .find((id) => {
          if (id === freeId) {
            freeId++;
            return false;
          } else {
            return true;
          }
        });
      const lastSelected = ids[ids.length - 1];
      if (election) {
        params[`${freeId}EventId`] = election.event_id;
        params[`${freeId}ParentShapeId`] = election.root_shape_id;
        params[`${freeId}RootShapeId`] = election.root_shape_id;
        params[`${freeId}RootIndicatorId`] = election.root_indicator_id.toString();
      } else if (lastSelected) {
        params[`${freeId}EventId`] = params[`${lastSelected}EventId`];
        params[`${freeId}ParentShapeId`] = params[`${lastSelected}RootShapeId`];
        params[`${freeId}RootShapeId`] = params[`${lastSelected}RootShapeId`];
        if (copyIndicator) {
          params[`${freeId}RootIndicatorId`] = params[`${lastSelected}RootIndicatorId`];
        }
      } else {
        params[`${freeId}EventId`] = electionsStore.defaultElection.event_id;
        params[`${freeId}ParentShapeId`] = electionsStore.defaultElection.root_shape_id;
        params[`${freeId}RootShapeId`] = electionsStore.defaultElection.root_shape_id;
        if (electionsStore.defaultElection.root_indicator_id) {
          params[`${freeId}RootIndicatorId`] =
            electionsStore.defaultElection.root_indicator_id.toString();
        }
      }
      params.ids = [...ids, freeId];
    },
    deleteElection(id) {
      const ids = params.ids || [];
      const index = ids.indexOf(id);
      if (index > -1) {
        params.ids.splice(index, 1);
        delete electionsStore.stores[id];
        delete params[`${id}EventId`];
        delete params[`${id}ParentShapeId`];
        delete params[`${id}RootShapeId`];
        delete params[`${id}RootIndicatorId`];
      }
    }
  });

  useEffect(() => {
    if (defaultElection) {
      electionsStore.defaultElection = defaultElection;
    }
  }, [defaultElection, electionsStore]);

  useEffect(() => {
    if (initElections?.length && electionsStore.storesArray.length === 0) {
      initElections.forEach((election) => electionsStore.createElection({ election }));
    }
  }, [initElections, electionsStore]);

  return electionsStore;
}
