import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  colorToolbar: {
    borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`,
    // boxShadow: theme.shadows[6],
    borderColor: theme.palette.borderColor,
    // border: '1px solid',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.75, 1, 0, 1),
    display: 'flex',
    minWidth: '260px',
    '& $colorTool:first-child div:first-child': {
      borderRadius: `5px 0 0 5px`
    },
    '& $colorTool:last-child div:first-child': {
      borderRadius: `0 5px 5px 0`
    }
  },
  colorTool: {
    '& div:first-child': {
      opacity: 0.85
    },
    '&:hover': {
      transition: theme.transitionAll,
      transform: 'scale3d(1.25, 1.25, 1)',
      zIndex: 1,
      '& div:first-child': {
        boxShadow: theme.shadows[2],
        opacity: 1
      }
    }
  }
}));

const ColoringInfo = view(({ fillColor, rootIndicatorId }) => {
  const classes = useStyles();

  return (
    !!rootIndicatorId &&
    !!fillColor?.stops?.length && (
      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        width={1}
        left={0}
        bottom={-1}
        zIndex={1}
      >
        <Box className={classes.colorToolbar}>
          {fillColor.stops.map(([value, color]) => (
            <Box
              display="flex"
              key={value}
              flexDirection="column"
              alignItems="center"
              className={classes.colorTool}
              flexBasis="100%"
            >
              <Box width={1} minWidth="25px" height="15px" bgcolor={color} />
              <Box fontSize={11} px={0.5} fontWeight={500}>
                {value}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    )
  );
});

export default ColoringInfo;
