import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useCollator() {
  const { i18n } = useTranslation();

  const collator = useMemo(
    () => new Intl.Collator(i18n.language, { numeric: true, sensitivity: 'base' }),
    [i18n]
  );

  return collator;
}
