import { Box } from '@material-ui/core';
import { FormatListBulleted as FormatListBulletedIcon } from '@material-ui/icons';
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';
import { autoEffect, view } from '@risingstack/react-easy-state';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';

const ResultRenderer = view(({ winnerIndicator, value }) => {
  if (
    value.name ===
    (winnerIndicator[apiKeys.indicatorCoreNameKey] || winnerIndicator[apiKeys.indicatorCoreNameKey])
  ) {
    return (
      <Box
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight={800}
      >
        <FiberManualRecordIcon style={{ fill: winnerIndicator.color, marginRight: '10px' }} />
        {value[value.headId]?.toFixed(2)} %
      </Box>
    );
  }

  return (
    <Box width={1} height={1}>
      {value[value.headId] != null ? `${value[value.headId]?.toFixed(2)} %` : '-'}
    </Box>
  );
});

const PartyTable = view(({ electionsStore, persistentParty }) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [headCells, setHeadCells] = useState({
    name: {
      canOrder: true,
      orderBy: 'name',
      label: t('party_name')
    },
    abbrvName: {
      label: t('party_name_abbrv'),
      hidden: true
    }
  });

  autoEffect(() => {
    const newHeadCells = {
      name: {
        canOrder: true,
        orderBy: 'name',
        label: t('party_name'),
        downloadable: true
      },
      abbrvName: {
        label: t('party_name_abbrv'),
        hidden: true
      }
    };
    const parties = electionsStore.storesArray.reduce((acc, electionStore) => {
      const feature = electionStore.parentShapeData?.feature;
      if (feature) {
        const header = `${electionStore.event.year}\n${
          electionStore.event[apiKeys.eventNameKey]
        }\n(${feature.properties[apiKeys.shapeNameKey]})`;
        const winnerIndicator =
          electionStore.parentShapeData.indicators[
            electionStore.parentShapeData.feature.properties.winner_root_indicator_id
          ];
        newHeadCells[header] = {
          canOrder: true,
          orderBy: header,
          label: header,
          downloadable: true,
          Renderer: function ResultRendererOuter(value) {
            return <ResultRenderer winnerIndicator={winnerIndicator} value={value} />;
          }
        };
        electionStore.categorizedIndicators.resultsIndicators.forEach((indicator) => {
          const partyName =
            indicator[apiKeys.indicatorCoreFullNameKey] || indicator[apiKeys.indicatorCoreNameKey];
          if (!acc[partyName]) {
            acc[partyName] = {
              name: partyName,
              abbrvName: indicator[apiKeys.indicatorCoreNameKey]
            };
          }
          const result = feature.properties[indicator.root_indicator_id];
          if (result) {
            acc[partyName][header] = result;
          }
        });
      }
      return acc;
    }, {});
    setRows(Object.values(parties));
    setHeadCells(newHeadCells);
  });

  return (
    <Box className="joyride-PartyTable">
      <Table
        rows={rows}
        headCells={headCells}
        defaultPagination={10}
        rowsPerPageOptions={[10, 20, 30]}
        defaultOrderBy="name"
        gridStyle
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormatListBulletedIcon />
            <Box ml={1}>{t('election_compare_table_summary')}</Box>
          </Box>
        }
        mt={5}
        searchBarFieldIds={['name', 'abbrvName']}
        idColumn="name"
        persistentRowId={persistentParty[apiKeys.indicatorCoreFullNameKey]}
        exporter
      />
    </Box>
  );
});

export default PartyTable;
