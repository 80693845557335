import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Lock as LockIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import React, { useContext } from 'react';

import { ProgressChart } from '../../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../../globalStores';
import ElectionContext from '../../ElectionContext';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: 'rgba(256, 256, 256, 0.95)',
    maxWidth: '300px',
    pointerEvents: 'none',
    borderRadius: theme.borderRadius,
    position: 'absolute',
    margin: theme.spacing(1),
    padding: theme.spacing(0.75, 1),
    zIndex: 9,
    boxShadow: theme.shadows[6],
    borderColor: theme.palette.borderColor,
    border: '1px solid'
  }
}));

const HoverInfo = view(() => {
  const classes = useStyles();
  const theme = useTheme();
  const { rootIndicatorId, hoverInfo, hoverPosition, collectionData, hoverLocked } =
    useContext(ElectionContext);

  const selectedIndicatorHoverInfo = hoverInfo?.[rootIndicatorId];

  return (
    !!hoverInfo && (
      <Box className={classes.tooltip} style={{ left: hoverPosition.x, top: hoverPosition.y }}>
        {hoverInfo[apiKeys.shapeNameKey] && (
          <Box fontSize={15} fontWeight={600} display="flex">
            {hoverLocked && (
              <Box mr={0.5} display="flex">
                <LockIcon fontSize="small" />
              </Box>
            )}
            <Box>{hoverInfo[apiKeys.shapeNameKey]}</Box>
          </Box>
        )}
        {selectedIndicatorHoverInfo != null &&
          (collectionData?.indicators[rootIndicatorId]?.number_format === '%' ? (
            <Box fontSize={14} fontWeight={500}>
              {collectionData?.indicators[rootIndicatorId]?.[apiKeys.indicatorCoreNameKey]}
              {collectionData?.indicators[rootIndicatorId]?.[apiKeys.indicatorParentCoreNameKey] &&
                ` (${
                  collectionData?.indicators[rootIndicatorId]?.[apiKeys.indicatorParentCoreNameKey]
                })`}
              {`: ${selectedIndicatorHoverInfo.toFixed(2)}%`}
              {
                <ProgressChart
                  progressBgColor={
                    collectionData?.indicators[rootIndicatorId]?.color ||
                    theme.palette.defaultIndicatorColor
                  }
                  percentage={selectedIndicatorHoverInfo}
                  height="15px"
                  my={0.5}
                />
              }
            </Box>
          ) : (
            <Box fontSize={14} fontWeight={500}>
              {collectionData?.indicators[rootIndicatorId]?.[apiKeys.indicatorCoreNameKey]}
              {collectionData?.indicators[rootIndicatorId]?.[apiKeys.indicatorParentCoreNameKey] &&
                ` (${
                  collectionData?.indicators[rootIndicatorId]?.[apiKeys.indicatorParentCoreNameKey]
                })`}
              {`: ${selectedIndicatorHoverInfo}`}
            </Box>
          ))}
      </Box>
    )
  );
});

export default HoverInfo;
