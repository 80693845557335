import { Box, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormatListBulleted as FormatListBulletedIcon } from '@material-ui/icons';
import { autoEffect, view } from '@risingstack/react-easy-state';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressChart, Table } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';

const useStyles = makeStyles(() => ({
  partyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    '&:hover': {
      display: 'block'
    }
  }
}));

const ResultsRenderer = ({ id, value }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box>
      {value[id]?.results.map((result) => (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          key={result.root_indicator_id}
          spacing={1}
        >
          <Grid item xs={5} component={Box} textAlign="left" className={classes.partyName}>
            {`${result[apiKeys.indicatorCoreNameKey]}${
              result[apiKeys.indicatorParentCoreNameKey]
                ? ` (${result[apiKeys.indicatorParentCoreNameKey]})`
                : ''
            }`}
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" justifyContent="center">
              <ProgressChart
                percentage={result.value}
                width="300px"
                height="18px"
                progressBgColor={result.color || theme.palette.defaultIndicatorColor}
                boxShadow={2}
                border={1}
              />
            </Box>
          </Grid>
          <Grid item xs={2} component={Box} textAlign="left">
            {`${result.value.toFixed(2)}%`}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

const VoterTurnoutRenderer = ({ id, value }) => {
  const { t } = useTranslation();

  return value[id] ? (
    <Box>
      <Box>{`${t('election_table_summary_turnout_total')}:`}</Box>
      <Box>{value[id]?.totalCount?.toLocaleString() || t('unknown')}</Box>
      {value[id]?.turnoutPercent && (
        <Box width={1} display="flex" justifyContent="center">
          <ProgressChart
            percentage={value[id].turnoutPercent}
            width="200px"
            height="18px"
            boxShadow={2}
            border={1}
            my={1}
          />
        </Box>
      )}
      <Box>{`${t('election_table_summary_turnout')}: ${
        value[id]?.turnoutPercent?.toFixed(2) || t('unknown')
      }% | ${value[id]?.turnoutCount?.toLocaleString() || t('unknown')}`}</Box>
    </Box>
  ) : (
    ''
  );
};

const SummaryCompareTable = view(({ electionsStore }) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  autoEffect(() => {
    const shapes = electionsStore.storesArray.reduce((acc, electionStore) => {
      const { turnoutPercentId, turnoutCountId, totalCountId } = {
        turnoutPercentId: electionStore?.categorizedIndicators?.otherIndicators.find(
          (indicator) => indicator.en_core_name_abbrv === 'Total Turnout (%)'
        )?.root_indicator_id,
        turnoutCountId: electionStore?.categorizedIndicators?.otherIndicators.find(
          (indicator) => indicator.en_core_name_abbrv === 'Total Turnout (#)'
        )?.root_indicator_id,
        totalCountId: electionStore?.categorizedIndicators?.otherIndicators.find(
          (indicator) => indicator.en_core_name_abbrv === 'Total Eligible Voters'
        )?.root_indicator_id
      };
      (electionStore?.collectionData?.collection?.features || [])
        .filter((feature) => feature.properties.winner_root_indicator_id)
        .forEach((feature) => {
          if (!acc[feature.properties[apiKeys.shapeNameKey]]) {
            acc[feature.properties[apiKeys.shapeNameKey]] = {
              name: feature.properties[apiKeys.shapeNameKey]
            };
          }
          acc[feature.properties[apiKeys.shapeNameKey]][electionStore?.event?.event_id] = {
            turnoutPercent: feature.properties[turnoutPercentId],
            turnoutCount: feature.properties[turnoutCountId],
            totalCount: feature.properties[totalCountId],
            results: electionStore?.categorizedIndicators?.resultsIndicators
              .filter((indicator) => feature.properties[indicator.root_indicator_id])
              .sort(
                (
                  { root_indicator_id: aRootIndicatorId },
                  { root_indicator_id: bRootIndicatorId }
                ) =>
                  feature.properties[aRootIndicatorId] > feature.properties[bRootIndicatorId]
                    ? -1
                    : 1
              )
              .slice(0, 3)
              .map((indicator) => ({
                ...indicator,
                value: feature.properties[indicator.root_indicator_id]
              }))
          };
        });
      return acc;
    }, {});
    setRows(Object.values(shapes));
  });

  const [headCells, setHeadCells] = useState({
    name: {
      canOrder: true,
      orderBy: 'name',
      label: t('election_table_summary_name')
    }
  });
  autoEffect(() =>
    setHeadCells({
      name: {
        canOrder: true,
        orderBy: 'name',
        label: t('election_table_summary_name')
      },
      ...electionsStore.storesArray.reduce((acc, electionStore) => {
        if (electionStore?.event?.event_id) {
          const resultsHeadKey = `${electionStore.event.event_id}_results`;
          const turnoutHeadKey = `${electionStore.event.event_id}_turnout`;
          if (!acc[resultsHeadKey]) {
            acc[resultsHeadKey] = {
              canOrder: false,
              label: `${t('election_table_summary_results')}:\n${electionStore?.event.year}\n${
                electionStore?.event[apiKeys.eventNameKey]
              }\n(${(
                electionStore?.parentShapeData?.feature?.properties[apiKeys.shapeNameKey] || ''
              ).toUpperCase()})`,
              Renderer: function ResultsRendererOuter(value) {
                return <ResultsRenderer id={electionStore.event.event_id} value={value} />;
              }
            };
            acc[turnoutHeadKey] = {
              canOrder: false,
              label: `${t('election_table_summary_voter_turnout')}:\n${
                electionStore?.event.year
              }\n${electionStore?.event[apiKeys.eventNameKey]}\n(${(
                electionStore?.parentShapeData?.feature?.properties[apiKeys.shapeNameKey] || ''
              ).toUpperCase()})`,
              Renderer: function VoterTurnoutRendererOuter(value) {
                return <VoterTurnoutRenderer id={electionStore.event.event_id} value={value} />;
              }
            };
          } else {
            acc[resultsHeadKey].label = `${t('election_table_summary_results')}:\n${
              electionStore?.event.year
            } ${electionStore?.event[apiKeys.eventNameKey]}`;
            acc[turnoutHeadKey].label = `${t('election_table_summary_voter_turnout')}:\n${
              electionStore?.event.year
            } ${electionStore?.event[apiKeys.eventNameKey]}`;
          }
        }
        return acc;
      }, {})
    })
  );

  return (
    <Box className="joyride-SummaryCompareTable">
      <Table
        rows={rows}
        headCells={headCells}
        defaultPagination={5}
        defaultOrderBy="name"
        gridStyle
        loading={electionsStore.loading}
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormatListBulletedIcon />
            <Box ml={1}>{t('election_compare_table_summary')}</Box>
          </Box>
        }
        mt={5}
        searchBarFieldIds={['name']}
        idColumn="name"
      />
    </Box>
  );
});

export default SummaryCompareTable;
