import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    padding: '0 !important',
    borderColor: theme.palette.borderColor,
    '& .sortIcon': {
      margin: theme.spacing(0, 0, 0, 0.5)
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: theme.palette.text.primary
      }
  },
  tableHeadCellBordered: {
    border: '1px solid'
  },
  tableHeadMargin: {
    '&::after': {
      content: '" "',
      display: 'block',
      marginBottom: theme.spacing(3)
    }
  },
  tableLabel: {
    padding: theme.spacing(3, 3, 3, 3),
    width: '100%',
    height: '100%',
    whiteSpace: 'pre',
    display: 'flex',
    justifyContent: ({ gridStyle }) => (gridStyle ? 'center' : 'flex-start')
  },
  tableSortLabel: {
    padding: `${theme.spacing(3, 0.5, 3, 3)} !important`,
    '&:hover': {
      color: theme.palette.text.primary
    }
  }
}));

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  selectedColumns,
  gridStyle
}) => {
  const classes = useStyles({ gridStyle });
  return (
    <TableHead className={clsx({ [classes.tableHeadMargin]: !gridStyle })}>
      <TableRow>
        {selectedColumns.map((id) =>
          headCells[id] ? (
            <TableCell
              key={id}
              align={gridStyle ? 'center' : 'left'}
              sortDirection={orderBy === headCells[id].orderBy ? order : false}
              className={clsx(classes.tableHeadCell, gridStyle && classes.tableHeadCellBordered)}
            >
              {headCells[id].canOrder ? (
                <TableSortLabel
                  active={orderBy === headCells[id].orderBy}
                  direction={orderBy === headCells[id].orderBy ? order : 'asc'}
                  onClick={() => onRequestSort(headCells[id].orderBy)}
                  classes={{
                    icon: 'sortIcon'
                  }}
                  className={clsx(
                    classes.tableLabel,
                    classes.tableSortLabel,
                    'joyride-TableHeadSort'
                  )}
                >
                  {headCells[id].label}
                </TableSortLabel>
              ) : (
                <span className={classes.tableLabel}>{headCells[id].label}</span>
              )}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  gridStyle: PropTypes.bool.isRequired
};

export default EnhancedTableHead;
