import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IMGElectionsPageMap } from '../images';

const SmallScreenWarning = () => {
  const { t } = useTranslation();

  return (
    <Box width={1} textAlign="center">
      <img
        src={IMGElectionsPageMap}
        alt="IMGElectionsPageMap"
        style={{ maxWidth: '700px', width: '100%' }}
      />
      <Box fontSize={18} p={4} whiteSpace="pre-wrap">
        {t('small_screen_warning')}
      </Box>
    </Box>
  );
};

export default SmallScreenWarning;
