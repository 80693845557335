import { Box } from '@material-ui/core';
import { EventBusy as EventBusyIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '../../../components';
import { eventsStore, localizedApiKeysStore as apiKeys } from '../../../globalStores';
import ElectionContext from '../ElectionContext';

const mainGroupPrecedence = {
  Parliamentary: 2,
  'Adjara SC': 4,
  Local: 3,
  Presidential: 1,
  საპარლამენტო: 2,
  აჭარის: 4,
  ადგილობრივი: 3,
  საპრეზიდენტო: 1
};

const mainGroupOrderer = (a, b) =>
  (mainGroupPrecedence[a] || 5) < (mainGroupPrecedence[b] || 5) ? -1 : 1;

const ElectionSelector = view(({ restrictedIds, ...rest }) => {
  const { t } = useTranslation();

  const { eventsWithYear } = eventsStore;
  const { eventId, changeEventId } = useContext(ElectionContext);

  const options = useMemo(
    () =>
      restrictedIds
        ? eventsWithYear.filter((event) => restrictedIds.includes(event.event_id))
        : eventsWithYear,
    [eventsWithYear, restrictedIds]
  );

  return (
    <Box className="joyride-ElectionSelector">
      <Select
        selectedText={t('election_selected')}
        selectedIcon={<EventBusyIcon />}
        options={options}
        groupKey={apiKeys.eventTypeKey}
        subGroupKey="year"
        optionLabelKey={apiKeys.eventNameKey}
        optionValueKey="event_id"
        onChange={changeEventId}
        value={eventId}
        mainGroupOrderer={mainGroupOrderer}
        {...rest}
      />
    </Box>
  );
});

ElectionSelector.propTypes = {
  restrictedIds: PropTypes.array
};

export default ElectionSelector;
