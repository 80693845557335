import { store } from '@risingstack/react-easy-state';

const localizedApiKeysStore = store({
  language: 'en',
  get eventNameKey() {
    return localizedApiKeysStore.language === 'en' ? 'en_name' : 'ka_name';
  },
  get shapeNameKey() {
    return localizedApiKeysStore.language === 'en' ? 'en_common_name' : 'ka_common_name';
  },
  get eventTypeKey() {
    return localizedApiKeysStore.language === 'en' ? 'en_event_type_name' : 'ka_event_type_name';
  },
  get indicatorCoreNameKey() {
    return localizedApiKeysStore.language === 'en' ? 'en_core_name_abbrv' : 'ka_core_name_abbrv';
  },
  get indicatorCoreFullNameKey() {
    return localizedApiKeysStore.language === 'en' ? 'en_core_name' : 'ka_core_name';
  },
  get indicatorParentCoreNameKey() {
    return localizedApiKeysStore.language === 'en'
      ? 'en_parent_core_name_abbrv'
      : 'ka_parent_core_name_abbrv';
  }
});

export default localizedApiKeysStore;
