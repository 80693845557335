import { Box, Button, CardActionArea, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CompareArrows as CompareArrowsIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccordionPanel, ProgressChart } from '../../components';
import { eventsStore, localizedApiKeysStore as apiKeys } from '../../globalStores';
import {
  Img2017local,
  Img2018pres,
  Img2018turnout,
  Img2020adjara,
  Img2020parliamentary,
  Img2020pgenderpng,
  IMGElectionsPageMap
} from '../../images';

const useStyles = makeStyles((theme) => ({
  highlight: {
    position: 'relative',
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    borderRadius: theme.borderRadius,
    height: '100%',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
    '&:hover $highlightInfo': {
      boxShadow: theme.shadows[6]
    }
  },
  highlightInfo: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    background: theme.palette.background.paper,
    borderRadius: `0 0 0 ${theme.borderRadius}`,
    padding: theme.spacing(1),
    opacity: 0.8,
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    boxShadow: theme.shadows[3],
    maxWidth: '80%',
    transition: theme.transitionAll
  },
  highlightText: {
    marginRight: theme.spacing(1),
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right'
  },
  event: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden'
  },
  eventInfo: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  eventName: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 500,
    fontSize: '15px'
  }
}));

const categories = ['presidential', 'parliamentary', 'local', 'adjara'];
const compareLinks = {
  presidential:
    '/election/compare?ids=%5B1%2C2%5D&1EventId=2&2EventId=38&1ParentShapeId=53148&2ParentShapeId=69898&1RootShapeId=53148&2RootShapeId=69898',
  parliamentary:
    '/election/compare?ids=%5B1%2C2%5D&2EventId=12&2RootShapeId=30695&2ParentShapeId=30695&1EventId=32&1ParentShapeId=65134&1RootShapeId=65134',
  adjara:
    '/election/compare?ids=%5B1%2C2%5D&1EventId=14&2EventId=34&1ParentShapeId=38814&2ParentShapeId=69547&1RootShapeId=38814&2RootShapeId=69547',
  local:
    '/election/compare?ids=%5B1%2C2%5D&1EventId=39&1RootShapeId=82062&1ParentShapeId=82062&2EventId=51&2RootShapeId=93196&2ParentShapeId=93196'
};

const ElectionSkeletons = ({ length = 4 }) => {
  const classes = useStyles();
  return (
    <>
      {[...Array(length)].map((e, i) => (
        <Grid item xs={3} key={i}>
          <CardActionArea className={classes.event}>
            <Box position="absolute" left={0} top={0} height={1}>
              <Skeleton variant="rect" width="25px" height="100%" animation="wave" />
            </Box>
            <Box ml={3.5} mr={0.5} height={1} width={1}>
              <Box mb={0.5} fontWeight={500} fontSize="15px">
                <Skeleton variant="rect" width="80%" height="21px" animation="wave" />
              </Box>
              <Box fontWeight={300}>
                <Skeleton variant="rect" width="85%" height="31px" animation="wave" />
              </Box>
            </Box>
          </CardActionArea>
        </Grid>
      ))}
    </>
  );
};

const Elections = view(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { eventsLoading, categorizedEvents } = eventsStore;

  return (
    <>
      <Grid container alignItems="flex-start">
        <Grid
          item
          xs={6}
          component={Box}
          fontSize={14}
          whiteSpace="pre-wrap"
          fontWeight={400}
          py={4}
        >
          {t('elections_information')}
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <img src={IMGElectionsPageMap} alt="IMGElectionsPageMap" height="400px" />
          </Box>
        </Grid>
      </Grid>
      <Box mb={6} mt={1}>
        <AccordionPanel title={t('elections_highlights_title')}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <CardActionArea
                component={Link}
                to="/election?eventId=74&rootShapeId=105361&parentShapeId=105361"
                className={classes.highlight}
              >
                <img src={Img2020parliamentary} alt="Img2020parliamentary" width="100%" />
                <Box className={classes.highlightInfo}>
                  <Box className={classes.highlightText}>
                    {t('elections_highlight_2020parliamentary')}
                  </Box>
                </Box>
              </CardActionArea>
            </Grid>
            <Grid item xs={4}>
              <CardActionArea
                component={Link}
                to="/election/compare?ids=%5B1%2C2%5D&1EventId=67&2EventId=68&1ParentShapeId=101639&2ParentShapeId=101639&1RootShapeId=101639&2RootShapeId=101639&1RootIndicatorId=null&2RootIndicatorId=null"
                className={classes.highlight}
              >
                <img src={Img2018pres} alt="Img2018pres" width="100%" />
                <Box className={classes.highlightInfo}>
                  <Box className={classes.highlightText}>{t('elections_highlight_2018pres')}</Box>
                </Box>
              </CardActionArea>
            </Grid>
            <Grid item xs={4}>
              <CardActionArea
                component={Link}
                to="/election?eventId=51&rootShapeId=109047&parentShapeId=109047"
                className={classes.highlight}
              >
                <img src={Img2017local} alt="Img2017local" width="100%" />
                <Box className={classes.highlightInfo}>
                  <Box className={classes.highlightText}>{t('elections_highlight_2017local')}</Box>
                </Box>
              </CardActionArea>
            </Grid>
            <Grid item xs={4}>
              <CardActionArea
                component={Link}
                to="/election/compare?ids=%5B1%2C2%5D&1EventId=74&2EventId=74&1ParentShapeId=105361&2ParentShapeId=105361&1RootShapeId=105361&2RootShapeId=105361&1RootIndicatorId=%227898%22&2RootIndicatorId=%227897%22&1Palette=%22purple%22&2Palette=%22green%22"
                className={classes.highlight}
              >
                <img src={Img2020pgenderpng} alt="Img2020pgenderpng" width="100%" />
                <Box className={classes.highlightText}>
                  <Box className={classes.highlightInfo}>
                    {t('elections_highlight_2020pgenderpng')}
                  </Box>
                </Box>
              </CardActionArea>
            </Grid>
            <Grid item xs={4}>
              <CardActionArea
                component={Link}
                to="/election/compare?ids=%5B1%2C2%5D&1EventId=67&2EventId=68&1ParentShapeId=101639&2ParentShapeId=101639&1RootShapeId=101639&2RootShapeId=101639&1RootIndicatorId=%227288%22&2RootIndicatorId=%227403%22&1Palette=%22pink%22&2Palette=%22pink%22"
                className={classes.highlight}
              >
                <img src={Img2018turnout} alt="Img2018turnout" width="100%" />
                <Box className={classes.highlightInfo}>
                  <Box className={classes.highlightText}>
                    {t('elections_highlight_2018turnout')}
                  </Box>
                </Box>
              </CardActionArea>
            </Grid>
            <Grid item xs={4}>
              <CardActionArea
                component={Link}
                to="/election/compare?ids=%5B3%2C4%2C5%2C6%5D&3EventId=82&3ParentShapeId=105361&3RootShapeId=105361&4EventId=82&4ParentShapeId=105364&4RootShapeId=105361&5EventId=82&5ParentShapeId=105390&5RootShapeId=105361&6EventId=82&6ParentShapeId=105391&6RootShapeId=105361"
                className={classes.highlight}
              >
                <img src={Img2020adjara} alt="Img2020adjara" width="100%" />
                <Box className={classes.highlightInfo}>
                  <Box className={classes.highlightText}>{t('elections_highlight_2020adjara')}</Box>
                </Box>
              </CardActionArea>
            </Grid>
          </Grid>
        </AccordionPanel>
      </Box>
      {categories.map((category) => (
        <Box my={6} key={category}>
          <AccordionPanel title={t(`elections_${category}_title`)}>
            <Box width={1} display="flex" flexWrap="wrap" justifyContent="center">
              <Box mb={4} width={1}>
                <Grid container spacing={3}>
                  {!eventsLoading ? (
                    categorizedEvents[category].map((event) => (
                      <Grid item xs={3} key={event.event_id}>
                        <CardActionArea
                          className={classes.event}
                          component={Link}
                          to={`/election?eventId=${event.event_id}&rootShapeId=${event.root_shape_id}&parentShapeId=${event.root_shape_id}`}
                        >
                          <Box position="absolute" left={0} top={1} height={1}>
                            <ProgressChart
                              vertical
                              percentage={event.winnerValue}
                              progressBgColor={event.winnerIndicator.color}
                              width="20px"
                            />
                          </Box>
                          <Box className={classes.eventInfo}>
                            <Box className={classes.eventName}>{event[apiKeys.eventNameKey]}</Box>
                            <Box fontWeight={300} whiteSpace="pre">
                              {`${event.winnerIndicator[apiKeys.indicatorCoreNameKey]}${
                                event.winnerIndicator[apiKeys.indicatorParentCoreNameKey]
                                  ? `\n(${
                                      event.winnerIndicator[apiKeys.indicatorParentCoreNameKey]
                                    })`
                                  : ''
                              } - ${event.winnerValue.toFixed(2)}%`}
                            </Box>
                          </Box>
                        </CardActionArea>
                      </Grid>
                    ))
                  ) : (
                    <ElectionSkeletons />
                  )}
                </Grid>
              </Box>
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                component={Link}
                to={compareLinks[category]}
                size="large"
              >
                {t(`elections_${category}_compare_button`)}
                <CompareArrowsIcon style={{ marginLeft: '8px' }} />
              </Button>
            </Box>
          </AccordionPanel>
        </Box>
      ))}
    </>
  );
});

export default Elections;
