import { Box, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/*
  Progress bar from -100% to +100%.
*/

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiCollapse-container': {
      width: '0px',
      transitionProperty: 'width'
    },
    '& .MuiCollapse-wrapper': {
      height: '100%'
    },
    '& .MuiCollapse-entered': {
      width: '100%'
    },
    '& .MuiCollapse-hidden': {
      width: '0px'
    }
  }
}));

const SeatChangeChart = ({ percentage, height, barHeight, boxShadow, ...rest }) => {
  const classes = useStyles();
  const percentageSign = useMemo(() => Math.sign(percentage), [percentage]);
  const percentageString = useMemo(() => `${Math.abs(percentage)}%`, [percentage]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={1}
      height={height}
      className={classes.root}
      {...rest}
    >
      <Box
        display="flex"
        alignItems="center"
        height={1}
        width="50%"
        borderRight={1}
        borderColor="primary.main"
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          height={barHeight}
          width={1}
          border={1}
          borderRight={0}
          borderColor="primary.main"
          boxShadow={boxShadow}
        >
          <Collapse appear in>
            <Box
              display="flex"
              height={1}
              width={percentageSign === -1 ? percentageString : 0}
              bgcolor="error.light"
              ml="auto"
            />
          </Collapse>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        height={barHeight}
        width="50%"
        border={1}
        borderLeft={0}
        borderColor="primary.main"
        boxShadow={boxShadow}
      >
        <Collapse appear in>
          <Box
            display="flex"
            height={1}
            width={percentageSign === 1 ? percentageString : 0}
            bgcolor="success.light"
          />
        </Collapse>
      </Box>
    </Box>
  );
};

SeatChangeChart.defaultProps = {
  height: '50px',
  barHeight: '25px'
};

SeatChangeChart.propTypes = {
  height: PropTypes.string,
  barHeight: PropTypes.string,
  percentage: PropTypes.number.isRequired
};

export default SeatChangeChart;
