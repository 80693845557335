import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack as ArrowBackIcon, LocationOn as LocationOnIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { view } from '@risingstack/react-easy-state';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { localizedApiKeysStore as apiKeys } from '../../../globalStores';
import { useCollator } from '../../../hooks';
import ElectionContext from '../ElectionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    minWidth: '145px',
    '& .MuiInput-underline:before': {
      margin: 'auto',
      borderRadius: theme.borderRadius,
      width: '99.9%'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  autocomplete: {
    borderTop: '1px solid',
    borderColor: theme.palette.borderColor,
    '& .MuiFormControl-root, & .MuiAutocomplete-inputRoot': {
      margin: '0 !important'
    },
    '& .MuiInputLabel-root, & .MuiInput-root': {
      padding: theme.spacing(1, 0, 1, 1),
      color: theme.palette.text.primary
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)'
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 9px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      visibility: 'hidden'
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  closeButton: {
    minWidth: '0',
    width: '24px',
    height: '100%'
  },
  indicatorName: {
    fontWeight: 500,
    fontSize: '13px',
    padding: theme.spacing(0, 1, 0.25, 1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    '&:hover': {
      display: 'block'
    }
  }
}));

const ControlPanel = view(({ backToParent, inRoot, maxWidth }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const collator = useCollator();
  const [inputValue, setInputValue] = useState('');
  const { collectionData, parentShapeData, changeParentShapeId, rootIndicatorId } =
    useContext(ElectionContext);

  const onSelect = useCallback(
    (e, value) => {
      if (value) {
        setInputValue('');
        changeParentShapeId(value.properties.shape_id);
      }
    },
    [changeParentShapeId]
  );

  const sortedOptions = useMemo(
    () =>
      collectionData?.collection?.features
        .filter(
          (feature) =>
            feature.properties.num_precincts &&
            (!rootIndicatorId || feature.properties[rootIndicatorId]) &&
            feature.properties.winner_root_indicator_id
        )
        .sort((a, b) =>
          collator.compare(a.properties[apiKeys.shapeNameKey], b.properties[apiKeys.shapeNameKey])
        ) || [],
    [collectionData?.collection?.features, rootIndicatorId, collator]
  );

  return (
    <Box className={clsx(classes.root, 'joyride-ControlPanel')} maxWidth={maxWidth}>
      <Box>
        <Box fontSize={12} px={0.5} pt={1} display="flex" alignItems="center">
          {!inRoot ? (
            <Button color="secondary" className={classes.closeButton} onClick={backToParent}>
              <ArrowBackIcon />
            </Button>
          ) : (
            <LocationOnIcon />
          )}

          <Box>
            <Box fontSize={12}>{t('election_current_view')}:</Box>
            <Box fontWeight={600} fontSize={16}>
              {parentShapeData?.feature?.properties[apiKeys.shapeNameKey]}
            </Box>
          </Box>
        </Box>
        <Box className={classes.indicatorName}>
          {rootIndicatorId && collectionData?.indicators[rootIndicatorId] ? (
            <>
              {collectionData?.indicators[rootIndicatorId][apiKeys.indicatorCoreNameKey]}
              {collectionData?.indicators[rootIndicatorId][apiKeys.indicatorParentCoreNameKey] &&
                ` (${
                  collectionData?.indicators[rootIndicatorId][apiKeys.indicatorParentCoreNameKey]
                })`}
            </>
          ) : (
            t('election_overall_results')
          )}
        </Box>
      </Box>
      <Autocomplete
        openOnFocus
        value={null}
        inputValue={inputValue}
        className={classes.autocomplete}
        options={sortedOptions}
        disabled={!sortedOptions.length}
        getOptionLabel={(option) => option.properties[apiKeys.shapeNameKey]}
        onChange={onSelect}
        onInputChange={(e, value) => setInputValue(value)}
        renderInput={(params) => (
          <TextField {...params} label={t('election_search')} margin="normal" />
        )}
      />
    </Box>
  );
});

ControlPanel.propTypes = {
  backToParent: PropTypes.func,
  inRoot: PropTypes.bool,
  maxWidth: PropTypes.number
};

export default ControlPanel;
