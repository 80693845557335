import { Box } from '@material-ui/core';
import { autoEffect, view } from '@risingstack/react-easy-state';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '../../../components';
import { localizedApiKeysStore as apiKeys, partiesStore } from '../../../globalStores';

const PartiesTable = view(() => {
  const { t } = useTranslation();

  const [headCells, setHeadCells] = useState({});
  autoEffect(() => {
    setHeadCells({
      [apiKeys.indicatorCoreNameKey]: {
        canOrder: true,
        orderBy: apiKeys.indicatorCoreNameKey,
        label: t('parties_latest_column_party'),
        Renderer: function PartyNameRenderer(value) {
          return <Box fontWeight={600}>{value[apiKeys.indicatorCoreNameKey]}</Box>;
        }
      },
      [apiKeys.indicatorCoreFullNameKey]: {
        canOrder: true,
        orderBy: apiKeys.indicatorCoreFullNameKey,
        label: t('parties_latest_column_party_full')
      },
      year_of_establishment: {
        canOrder: true,
        orderBy: 'year_of_establishment',
        label: t('parties_latest_column_establishment')
      }
    });
  });

  return (
    <Table
      title={t('parties_list_title')}
      loading={partiesStore.partiesLoading}
      rows={partiesStore.parties}
      headCells={headCells}
      defaultPagination={15}
      rowsPerPageOptions={[10, 15, 30, 90, 150]}
      defaultOrderBy={apiKeys.indicatorCoreNameKey}
      searchBarFieldIds={[apiKeys.indicatorCoreNameKey, apiKeys.indicatorCoreFullNameKey]}
      idColumn={apiKeys.indicatorCoreNameKey}
      minWidth="1000px"
      rowLinks
    />
  );
});

export default PartiesTable;
