import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Timeline as TimelineIcon } from '@material-ui/icons';
import { autoEffect, view } from '@risingstack/react-easy-state';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import { AccordionPanel, MultiSelect, TitledPaper } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';

const PartyLineChart = view(
  ({ electionsStore, width, height, paddingX, paddingY, persistentParty, ...rest }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [selectedParties, setSelectedParties] = useState([]);
    const [parties, setParties] = useState([]);
    const [availableParties, setAvailableParties] = useState({});
    autoEffect(() => {
      if (!electionsStore.loading || persistentParty) {
        const defaultParties = new Set();
        const newParties = {};

        electionsStore.storesArray.forEach((electionStore) => {
          electionStore.parentSortedResultsIndicators.forEach((indicator, i) => {
            if (i < 3) {
              defaultParties.add(indicator[apiKeys.indicatorCoreNameKey]);
            }
            newParties[indicator[apiKeys.indicatorCoreNameKey]] = indicator.color;
          });
        });

        if (persistentParty) {
          delete newParties[persistentParty[apiKeys.indicatorCoreNameKey]];
        }

        setParties(newParties);
        const newAvailableParties = Object.keys(newParties)
          .sort()
          .reduce((acc, newParty) => ({ ...acc, [newParty]: newParty }), {});
        setAvailableParties(newAvailableParties);
        setSelectedParties((selectedParties) =>
          selectedParties.length || persistentParty
            ? selectedParties.filter((p) => Object.keys(newAvailableParties).includes(p))
            : [...defaultParties]
        );
      }
    });

    const [data, setData] = useState([{}]);
    autoEffect(() => {
      if (!electionsStore.loading || persistentParty) {
        if (electionsStore.storesArray.length) {
          const newData = [];
          const addedElections = new Set();
          electionsStore.storesArray.forEach((electionStore) => {
            if (electionStore.event && electionStore.parentShapeData) {
              const election = `${electionStore.event?.year} ${
                electionStore.event[apiKeys.eventNameKey]
              } (${electionStore.parentShapeData?.feature?.properties[apiKeys.shapeNameKey]})`;
              if (!addedElections.has(election)) {
                addedElections.add(election);
                newData.push({
                  election,
                  ...electionStore.categorizedIndicators.resultsIndicators.reduce(
                    (acc, indicator) => {
                      acc[indicator[apiKeys.indicatorCoreNameKey]] =
                        electionStore.parentShapeData?.feature?.properties[
                          indicator.root_indicator_id
                        ];
                      return acc;
                    },
                    {}
                  )
                });
              }
            }
          });
          setData(newData);
        } else {
          setData([{}]);
        }
      }
    });

    return (
      <Box className="joyride-PartyLineChart">
        <AccordionPanel
          title={
            <Box display="flex" alignItems="center" justifyContent="center">
              <TimelineIcon />
              <Box ml={1}>{t('election_compare_parties_title')}</Box>
            </Box>
          }
        >
          <Box width={1} display="flex" flexDirection="column">
            <Box className="joyride-PartyLineChartSelector">
              <MultiSelect
                options={availableParties}
                setSelected={setSelectedParties}
                selected={selectedParties}
                label={t('election_compare_select_parties')}
              />
            </Box>
            <TitledPaper p={2}>
              <LineChart
                width={width}
                height={height}
                data={data}
                margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                {...rest}
              >
                {persistentParty && (
                  <Line
                    type="linear"
                    dataKey={persistentParty[apiKeys.indicatorCoreNameKey]}
                    stroke={persistentParty.color || theme.palette.defaultIndicatorColor}
                    dot={{
                      stroke: persistentParty.color || theme.palette.defaultIndicatorColor,
                      strokeWidth: 3,
                      r: 4
                    }}
                    strokeWidth={2}
                    animationBegin={0}
                    animationDuration={300}
                  />
                )}
                {selectedParties.map((party) => (
                  <Line
                    type="linear"
                    key={party}
                    dataKey={party}
                    stroke={parties[party] || theme.palette.defaultIndicatorColor}
                    dot={{
                      stroke: parties[party] || theme.palette.defaultIndicatorColor,
                      strokeWidth: 3,
                      r: 4
                    }}
                    strokeWidth={2}
                    animationBegin={0}
                    animationDuration={300}
                  />
                ))}
                <XAxis
                  dataKey="election"
                  padding={paddingX}
                  dy={20}
                  interval={0}
                  angle={-5}
                  height={65}
                />
                <YAxis tickFormatter={(tick) => `${tick}%`} padding={paddingY} />
                <Tooltip
                  formatter={(value) => `${value.toFixed(2)}%`}
                  wrapperStyle={{ zIndex: 1 }}
                  contentStyle={{
                    borderRadius: theme.borderRadius,
                    boxShadow: theme.shadows[6],
                    borderColor: theme.palette.borderColor
                  }}
                  itemSorter={(item) => -item.value}
                />
                <Legend verticalAlign="top" iconSize={20} />
              </LineChart>
            </TitledPaper>
          </Box>
        </AccordionPanel>
      </Box>
    );
  }
);

PartyLineChart.defaultProps = {
  width: 1000,
  height: 300,
  paddingX: { left: 100, right: 100 },
  paddingY: { top: 30, bottom: 20 }
};

export default PartyLineChart;
