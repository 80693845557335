import { default as IMGPartyMap2 } from './party_map_2.png';
import { default as IMGPartyMap11 } from './party_map_11.png';
import { default as IMGPartyMap24 } from './party_map_24.png';
import { default as IMGPartyMap71 } from './party_map_71.png';
import { default as IMGPartyMap75 } from './party_map_75.png';
import { default as IMGPartyMap134 } from './party_map_134.png';

export { default as Img2017local } from './2017local.png';
export { default as Img2018pres } from './2018pres.png';
export { default as Img2018turnout } from './2018turnout.png';
export { default as Img2020adjara } from './2020adjara.png';
export { default as Img2020parliamentary } from './2020parliamentary.png';
export { default as Img2020pgenderpng } from './2020pgenderpng.png';
export { default as IMGDataPageMap } from './dataPageMap.png';
export { default as IMGElectionsPageMap } from './electionsPageMap.png';
export { default as IMGNDI } from './ndi_logo.png';
export { default as IMGPartyPlaceholder } from './partyPlaceholder.png';
export { default as IMGRisingstackLogo } from './risingstack-logo.svg';

export const partyMaps = {
  IMGPartyMap2,
  IMGPartyMap11,
  IMGPartyMap24,
  IMGPartyMap71,
  IMGPartyMap75,
  IMGPartyMap134
};
