import { Box, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormatListBulleted as FormatListBulletedIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressChart, Table } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';
import ElectionContext from '../ElectionContext';

const useStyles = makeStyles(() => ({
  partyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    '&:hover': {
      display: 'block'
    }
  }
}));

const ResultsRenderer = view((value) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box>
      {value.results.map((result) => (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          key={result.root_indicator_id}
          spacing={1}
        >
          <Grid item xs={5} component={Box} textAlign="left" className={classes.partyName}>
            {`${result[apiKeys.indicatorCoreNameKey]}${
              result[apiKeys.indicatorParentCoreNameKey]
                ? ` (${result[apiKeys.indicatorParentCoreNameKey]})`
                : ''
            }`}
          </Grid>
          <Grid item xs={1} component={Box} textAlign="right">
            {Math.round((value.turnoutCount * result.value) / 100).toLocaleString()}
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" justifyContent="center">
              <ProgressChart
                percentage={result.value}
                width="360px"
                height="18px"
                progressBgColor={result.color || theme.palette.defaultIndicatorColor}
                boxShadow={2}
                border={1}
              />
            </Box>
          </Grid>
          <Grid item xs={1} component={Box} textAlign="left">
            {`${result.value.toFixed(2)}%`}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
});

const VoterTuroutRenderer = (value) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box>{`${t('election_table_summary_turnout_total')}:`}</Box>
      <Box>{value.totalCount?.toLocaleString() || t('unknown')}</Box>
      {value.turnoutPercent && (
        <Box width={1} display="flex" justifyContent="center">
          <ProgressChart
            percentage={value.turnoutPercent || 0}
            width="200px"
            height="18px"
            my={1}
            boxShadow={2}
            border={1}
          />
        </Box>
      )}
      <Box>{`${t('election_table_summary_turnout')}: ${
        value.turnoutPercent?.toFixed(2) || t('unknown')
      }% | ${value.turnoutCount?.toLocaleString() || t('unknown')}`}</Box>
    </Box>
  );
};

const SummaryTable = view(() => {
  const {
    collectionData,
    parentShapeData,
    categorizedIndicators: { otherIndicators, resultsIndicators },
    loading
  } = useContext(ElectionContext);
  const { t } = useTranslation();

  const { turnoutPercentId, turnoutCountId, totalCountId } = useMemo(
    () => ({
      turnoutPercentId: otherIndicators.find(
        (indicator) => indicator.en_core_name_abbrv === 'Total Turnout (%)'
      )?.root_indicator_id,
      turnoutCountId: otherIndicators.find(
        (indicator) => indicator.en_core_name_abbrv === 'Total Turnout (#)'
      )?.root_indicator_id,
      totalCountId: otherIndicators.find(
        (indicator) => indicator.en_core_name_abbrv === 'Total Eligible Voters'
      )?.root_indicator_id
    }),
    [otherIndicators]
  );

  const rows = useMemo(
    () =>
      (collectionData?.collection?.features || [])
        .filter((feature) => feature.properties.winner_root_indicator_id)
        .map((feature) => ({
          name: feature.properties[apiKeys.shapeNameKey],
          turnoutPercent: feature.properties[turnoutPercentId],
          turnoutCount: feature.properties[turnoutCountId],
          totalCount: feature.properties[totalCountId],
          results: [...resultsIndicators]
            .sort(
              ({ root_indicator_id: aRootIndicatorId }, { root_indicator_id: bRootIndicatorId }) =>
                (feature.properties[aRootIndicatorId] || 0) >
                (feature.properties[bRootIndicatorId] || 0)
                  ? -1
                  : 1
            )
            .slice(0, 3)
            .filter((indicator) => feature.properties[indicator.root_indicator_id])
            .map((indicator) => ({
              ...indicator,
              value: feature.properties[indicator.root_indicator_id]
            }))
        })),
    [
      collectionData?.collection?.features,
      turnoutPercentId,
      turnoutCountId,
      totalCountId,
      resultsIndicators
    ]
  );

  const headCells = useMemo(
    () => ({
      name: {
        canOrder: true,
        orderBy: 'name',
        label: t('election_table_summary_name')
      },
      results: {
        canOrder: false,
        label: t('election_table_summary_results'),
        Renderer: ResultsRenderer
      },
      turnout: {
        canOrder: true,
        orderBy: 'turnoutPercent',
        label: t('election_table_summary_voter_turnout'),
        Renderer: VoterTuroutRenderer
      }
    }),
    [t]
  );

  return (
    <Box className="joyride-SummaryTable">
      <Table
        rows={rows}
        headCells={headCells}
        defaultPagination={5}
        defaultOrderBy="name"
        gridStyle
        loading={loading}
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormatListBulletedIcon />
            <Box ml={1}>{`${t('election_table_summary')} ${(
              parentShapeData?.feature?.properties[apiKeys.shapeNameKey] || ''
            ).toUpperCase()}`}</Box>
          </Box>
        }
        my={8}
        searchBarFieldIds={['name']}
        idColumn="name"
      />
    </Box>
  );
});

export default SummaryTable;
