import { Box, Button, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { store, view } from '@risingstack/react-easy-state';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../../api';
import { AccordionPanel } from '../../components';
import { eventsStore, localizedApiKeysStore as apiKeys } from '../../globalStores';
import { IMGDataPageMap } from '../../images';

const useStyles = makeStyles((theme) => ({
  alertBox: {
    borderRadius: theme.borderRadius
  },
  dataRow: {
    padding: theme.spacing(2),
    borderRadius: theme.borderRadius,
    transition: theme.transitionAll,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[6],
      transform: 'scale(1.02)'
    }
  },
  mainImg: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    }
  }
}));

const categories = ['presidential', 'parliamentary', 'adjara', 'local', 'voters_list'];

const Data = view(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAlert, setOpenAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const dataStore = store({
    get events() {
      return { ...eventsStore.categorizedEvents, voters_list: eventsStore.votersLists };
    },
    get loading() {
      return eventsStore.eventsLoading || eventsStore.votersListsLoading;
    }
  });

  const downloadFile = useCallback(({ eventId, language, extension }) => {
    api({
      url: '/exports',
      method: 'GET',
      responseType: 'blob',
      params: { eventId, language, extension }
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const headerLine = response.headers['content-disposition'];
        if (headerLine) {
          const startFileNameIndex = headerLine.indexOf('"') + 1;
          const endFileNameIndex = headerLine.lastIndexOf('"');
          link.setAttribute(
            'download',
            decodeURIComponent(headerLine.substring(startFileNameIndex, endFileNameIndex))
          );
        } else {
          link.setAttribute('download', `${eventId}_${language}.${extension}`);
        }
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setOpenAlert(true);
      });
  }, []);

  if (dataStore.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={20}>
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  return (
    <>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
          className={classes.alertBox}
        >
          {t('data_not_found')}
        </Alert>
      </Snackbar>
      <Grid container alignItems="flex-start">
        <Grid
          item
          md={6}
          component={Box}
          fontSize={14}
          whiteSpace="pre-wrap"
          fontWeight={400}
          py={4}
        >
          {t('data_information')}
        </Grid>
        <Grid item md={6} component={Box} width={1}>
          <Box className={classes.mainImg}>
            <img src={IMGDataPageMap} alt="IMGDataPageMap" height="280px" />
          </Box>
        </Grid>
      </Grid>
      {categories.map((category) => (
        <Box my={4} key={category}>
          <AccordionPanel title={t(`data_${category}_title`)}>
            <Box width={1}>
              {!!dataStore.events[category].length &&
                dataStore.events[category].map((event) => (
                  <Grid
                    container
                    alignItems="center"
                    key={event.event_id}
                    className={classes.dataRow}
                  >
                    <Grid item xs={5} component={Box} fontSize={16} fontWeight={500}>
                      {event[apiKeys.eventNameKey]}
                    </Grid>
                    <Grid item xs={7} component={Box} textAlign="right">
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={6} md={3} component={Box} textAlign="center">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              downloadFile({
                                eventId: event.event_id,
                                language: 'en',
                                extension: 'csv'
                              })
                            }
                          >
                            English - CSV
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={3} component={Box} textAlign="center">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              downloadFile({
                                eventId: event.event_id,
                                language: 'en',
                                extension: 'xls'
                              })
                            }
                          >
                            English - XLS
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={3} component={Box} textAlign="center">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              downloadFile({
                                eventId: event.event_id,
                                language: 'ka',
                                extension: 'csv'
                              })
                            }
                          >
                            ქართული - CSV
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={3} component={Box} textAlign="center">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              downloadFile({
                                eventId: event.event_id,
                                language: 'ka',
                                extension: 'xls'
                              })
                            }
                          >
                            ქართული - XLS
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Box>
          </AccordionPanel>
        </Box>
      ))}
    </>
  );
});

export default Data;
