import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    backgroundColor: 'transparent',
    '&.MuiAccordion-root:before': {
      height: 0
    }
  },
  title: {
    textTransform: 'uppercase',
    padding: theme.spacing(0.25, 0, 0.25, 3),
    fontWeight: 600,
    fontSize: '16px',
    textAlign: 'center',
    width: '100%'
  },
  accordionSummary: {
    padding: theme.spacing(0.5, 0),
    marginTop: '0 !important',
    marginBottom: '0 !important',
    minHeight: '0 !important',
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.background.header,
    color: theme.palette.text.secondary
  },
  expandIcon: {
    padding: theme.spacing(0.75, 0),
    position: 'absolute',
    left: '10px',
    bottom: 0,
    color: theme.palette.text.secondary
  },
  accordionContent: {
    marginTop: '0 !important',
    marginBottom: '0 !important'
  },
  accordionDetails: {
    marginTop: theme.spacing(3),
    padding: 0
  }
}));

const AccordionPanel = ({ defaultExpanded, title, children, ...rest }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion
      square
      elevation={0}
      onChange={(event, expanded) => setExpanded(expanded)}
      expanded={expanded}
      classes={{
        root: classes.accordionRoot
      }}
      {...rest}
    >
      <AccordionSummary
        expandIcon={expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        className={classes.accordionSummary}
        classes={{
          expandIcon: classes.expandIcon,
          content: classes.accordionContent
        }}
      >
        <Box className={classes.title}>{title}</Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};

AccordionPanel.defaultProps = {
  defaultExpanded: true
};

AccordionPanel.propTypes = {
  title: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node
};

export default AccordionPanel;
