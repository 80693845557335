import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CompareArrows as CompareArrowsIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import bbox from '@turf/bbox';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebMercatorViewport } from 'react-map-gl';
import { Link } from 'react-router-dom';

import { countryViewport, ZOOM_TRANSITION_DURATION } from '../../../../constants';
import { useWindowSize } from '../../../../hooks';
import ElectionContext from '../../ElectionContext';
import { ControlPanel, IndicatorPanel, PalettePanel, StylePanel } from '../';
import Map from './Map';

const useStyles = makeStyles(() => ({
  root: {
    '& .overlays': {
      overflow: 'visible !important'
    }
  },
  rootFullScreen: {
    position: 'fixed',
    zIndex: 1100,
    left: 0,
    top: 0
  }
}));

const ElectionMap = view(
  ({
    compareButton,
    showStylePanel,
    showIndicatorPanel,
    defaultHeight,
    defaultWidth,
    padding,
    styleDefaults,
    disableFullScreen,
    scrollZoom,
    defaultExpandedPalette,
    ...rest
  }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [mapStyle, setMapStyle] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const windowSize = useWindowSize();
    const {
      eventId,
      parentShapeId,
      rootShapeId,
      rootIndicatorId,
      collectionData,
      parentShapeData,
      changeParentShapeId
    } = useContext(ElectionContext);

    const [width, height] = useMemo(
      () => (isFullScreen ? [windowSize.width, windowSize.height] : [defaultWidth, defaultHeight]),
      [defaultHeight, defaultWidth, isFullScreen, windowSize]
    );

    const currentDefaultViewport = useMemo(() => {
      if (collectionData?.collection) {
        let collection = { ...collectionData.collection };
        if (rootIndicatorId != null) {
          collection.features = collection.features.filter(
            (feature) => feature.properties.winner_root_indicator_id != null
          );
        }
        const [minLng, minLat, maxLng, maxLat] = bbox(collection);
        const vp = new WebMercatorViewport({
          width,
          height
        });
        const { longitude, latitude, zoom } = vp.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          {
            padding: {
              top: padding.top,
              bottom: padding.bottom,
              left: padding.left,
              right: padding.right
            }
          }
        );
        return {
          width: 1230,
          height: 500,
          bearing: 0,
          pitch: 0,
          longitude,
          latitude,
          zoom,
          transitionDuration: ZOOM_TRANSITION_DURATION
        };
      } else {
        return countryViewport;
      }
    }, [
      collectionData?.collection,
      rootIndicatorId,
      width,
      height,
      padding.top,
      padding.bottom,
      padding.left,
      padding.right
    ]);

    const toggleFullScreen = useCallback(() => {
      setIsFullScreen((v) => !v);
    }, []);

    const inRoot = useMemo(() => parentShapeId === rootShapeId, [parentShapeId, rootShapeId]);

    const backToParent = useCallback(() => {
      if (parentShapeData?.feature?.properties?.parent_shape_id && !inRoot) {
        if (
          parentShapeData.feature.properties.en_shape_type_name === 'District' &&
          parentShapeData.feature.properties.parent_shape_id !== rootShapeId
        ) {
          changeParentShapeId(rootShapeId);
        } else {
          changeParentShapeId(parentShapeData.feature.properties.parent_shape_id);
        }
      }
    }, [
      changeParentShapeId,
      inRoot,
      parentShapeData?.feature?.properties?.en_shape_type_name,
      parentShapeData?.feature?.properties?.parent_shape_id,
      rootShapeId
    ]);

    return (
      <Box className={clsx(classes.root, isFullScreen && classes.rootFullScreen)}>
        <Map
          currentDefaultViewport={currentDefaultViewport}
          mapStyle={mapStyle}
          width={width}
          height={height}
          scrollZoom={scrollZoom}
          backToParent={backToParent}
          disableFullScreen={disableFullScreen}
          toggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
          {...rest}
        />
        <Box position="absolute" right={-1} top={-1} zIndex={2}>
          <Box mb={1} display={showStylePanel ? 'block' : 'none'}>
            <StylePanel onChange={setMapStyle} defaults={styleDefaults} maxWidth={padding.right} />
          </Box>
          {rootIndicatorId != null && (
            <Box mb={1}>
              <PalettePanel maxWidth={padding.right} defaultExpanded={defaultExpandedPalette} />
            </Box>
          )}
          <ControlPanel backToParent={backToParent} inRoot={inRoot} maxWidth={padding.right} />
        </Box>
        {showIndicatorPanel && (
          <Box position="absolute" left={-1} top={-1} zIndex={2}>
            <IndicatorPanel maxHeight={height - 90} />
          </Box>
        )}
        {compareButton && (
          <Box
            position="absolute"
            left={-1}
            bottom={-1}
            zIndex={2}
            className="joyride-CompareButton"
          >
            <Button
              variant="outlined"
              component={Link}
              to={`/election/compare?ids=%5B1%2C2%5D&1EventId=${eventId}&2EventId=${eventId}&1ParentShapeId=${parentShapeId}&2ParentShapeId=${parentShapeId}&1RootShapeId=${rootShapeId}&2RootShapeId=${rootShapeId}`}
            >
              <CompareArrowsIcon />
              <Box display="inline" ml={0.5}>
                {t('election_compare_button')}
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

ElectionMap.defaultProps = {
  showIndicatorPanel: true,
  showStylePanel: true,
  compareButton: true,
  defaultExpandedPalette: true,
  scrollZoom: { speed: 0.2, smooth: true },
  defaultHeight: 460,
  defaultWidth: 1232,
  padding: { top: 10, bottom: 40, left: 270, right: 165 }
};

ElectionMap.propTypes = {
  scrollZoom: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  showStylePanel: PropTypes.bool,
  showIndicatorPanel: PropTypes.bool,
  compareButton: PropTypes.bool,
  disableFullScreen: PropTypes.bool,
  defaultExpandedPalette: PropTypes.bool,
  defaultHeight: PropTypes.number,
  defaultWidth: PropTypes.number,
  styleDefaults: PropTypes.shape({}),
  padding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  })
};

export default ElectionMap;
