import { Box, CardActionArea, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { Link } from 'react-router-dom';

import { localizedApiKeysStore as apiKeys, partiesStore } from '../../globalStores';
import { IMGPartyPlaceholder, partyMaps } from '../../images';
import { LatestElectionTable, PartiesTable } from './components';

const useStyles = makeStyles((theme) => ({
  highlight: {
    position: 'relative',
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    borderRadius: theme.borderRadius,
    height: '100%',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
    padding: theme.spacing(1),
    '&:hover $partyHighlightInfo': {
      boxShadow: theme.shadows[6]
    }
  },
  partyHighlightInfo: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    background: theme.palette.background.paper,
    borderRadius: `0 0 0 ${theme.borderRadius}`,
    padding: theme.spacing(1),
    opacity: 0.8,
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    boxShadow: theme.shadows[3],
    maxWidth: '80%',
    transition: theme.transitionAll
  },
  highlightText: {
    marginRight: theme.spacing(1),
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right'
  },
  partyImg: {
    width: '40px',
    borderRadius: '6px',
    height: '100%'
  }
}));

const highlights = [24, 71, 11, 2, 75, 134];

const Parties = view(() => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        {highlights.map((id) => (
          <Grid item xs={4} key={id}>
            <CardActionArea component={Link} to={`/party/${id}`} className={classes.highlight}>
              <img src={partyMaps[`IMGPartyMap${id}`]} alt="ImgGBMap" width="100%" />
              <Box className={classes.partyHighlightInfo}>
                <Box className={classes.highlightText}>
                  {partiesStore.partiesById[id]?.[apiKeys.indicatorCoreFullNameKey].replace(
                    ' - ',
                    '\n'
                  )}
                </Box>
                <img
                  src={partiesStore.partiesById[id]?.party_logo_url || IMGPartyPlaceholder}
                  alt={`partyIMG_${id}`}
                  className={classes.partyImg}
                />
              </Box>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
      <Box width={1} my={4} display="flex" justifyContent="center">
        <LatestElectionTable />
      </Box>
      <Box width={1} my={6} display="flex" justifyContent="center">
        <PartiesTable />
      </Box>
    </>
  );
});

export default Parties;
