import { Box, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Launch as LaunchIcon, Link as LinkIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TitledPaper } from '../../components';
import { localizedApiKeysStore as apiKeys } from '../../globalStores';
import { useElectionData } from '../../hooks';
import {
  DetailsTable,
  ElectionJoyride,
  ElectionMap,
  ElectionSelector,
  ParliamentaryChart,
  SummaryTable,
  TurnoutChart
} from './components';
import ElectionContext from './ElectionContext';

const useStyles = makeStyles((theme) => ({
  mapBox: {
    position: 'relative'
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '100%',
    height: '100%'
  },
  linkText: {
    fontWeight: 300,
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    '&:hover': {
      display: 'block'
    }
  },
  relatedEvent: {
    flexGrow: 1,
    padding: theme.spacing(0)
  },
  listRoot: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '195px'
  }
}));

const Election = view(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const electionStore = useElectionData({
    eventIdKey: 'eventId',
    parentShapeIdKey: 'parentShapeId',
    rootShapeIdKey: 'rootShapeId',
    rootIndicatorIdKey: 'rootIndicatorId',
    paletteKey: 'palette'
  });

  return (
    <ElectionContext.Provider value={electionStore}>
      <ElectionJoyride />
      <Box mb={1} mt={-1}>
        <ElectionSelector />
      </Box>
      <Box className={classes.mapBox}>
        <ElectionMap scrollZoom={false} />
      </Box>
      <Box mt={1.75}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <TurnoutChart radius={180} />
          </Grid>
          <Grid item md={6}>
            <ParliamentaryChart radius={300} />
          </Grid>
          <Grid item md={2}>
            <TitledPaper
              className="joyride-RelatedElections"
              title={
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LaunchIcon fontSize="small" />
                  <Box ml={1}>{t('election_related_elections')}</Box>
                </Box>
              }
            >
              {electionStore.relatedEvents.length ? (
                <List className={classes.listRoot} disablePadding>
                  {electionStore.relatedEvents.map((event) => (
                    <ListItem key={event.event_id} button className={classes.relatedEvent}>
                      <Box
                        className={classes.link}
                        component={Link}
                        to={`/election?eventId=${event.event_id}&rootShapeId=${event.root_shape_id}&parentShapeId=${event.root_shape_id}`}
                      >
                        <LinkIcon style={{ transform: 'rotate(-30deg)' }} />
                        <Box ml={1} className={classes.linkText}>
                          {event.year} {event[apiKeys.eventNameKey]}
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height="100%"
                  style={{ minHeight: '195px' }}
                  animation="wave"
                />
              )}
            </TitledPaper>
          </Grid>
        </Grid>
      </Box>
      <DetailsTable />
      <SummaryTable />
    </ElectionContext.Provider>
  );
});

export default Election;
