import Axios from 'axios';
import { configure } from 'axios-hooks';

const REQUEST_TIMEOUT = 30000;

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  }
});

configure({ axios });

export default axios;
