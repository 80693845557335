import { Box, Checkbox, List, ListItem, ListItemText } from '@material-ui/core';
import { ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Layers as LayersIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionBox } from '../../../components';
import { MapStyle } from '../../../constants';

const defaultMapStyle = fromJS(MapStyle);
const defaultLayers = defaultMapStyle.get('layers');

const categories = ['labels', 'nature', 'parks', 'water', 'roads'];

const layerSelector = {
  water: /water/,
  nature: /nature/,
  parks: /park/,
  roads: /bridge|road|tunnel/,
  labels: /label|place|poi/
};

const getMapStyle = ({ visibility }) =>
  defaultMapStyle.set(
    'layers',
    defaultLayers.filter((layer) => {
      const id = layer.get('id');
      return categories.every((name) => visibility[name] || !layerSelector[name].test(id));
    })
  );

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 0)
  },
  listItem: {
    padding: theme.spacing(0, 2)
  },
  checkbox: {
    padding: 0,
    margin: theme.spacing(0, 1.5, 0, 0),
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary
    }
  },
  checkboxLabel: {
    margin: 0
  }
}));

const StylePanel = view(({ onChange, defaults, maxWidth }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState(defaults);

  useEffect(() => {
    onChange(getMapStyle({ visibility }));
  }, [visibility, onChange]);

  const onVisibilityChange = useCallback((name) => {
    setVisibility((visibility) => ({ ...visibility, [name]: !visibility[name] }));
  }, []);

  return (
    <Box maxWidth={maxWidth} className="joyride-StylePanel">
      <AccordionBox
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <LayersIcon fontSize="small" />
            <Box ml={0.5}>{t('election_map_layers')}</Box>
          </Box>
        }
      >
        <List className={classes.listRoot}>
          {categories.map((name) => (
            <ListItem
              key={name}
              button
              onClick={() => onVisibilityChange(name)}
              className={classes.listItem}
            >
              <ListItemIcon style={{ minWidth: '0' }}>
                <Checkbox
                  size="small"
                  checked={visibility[name]}
                  color="primary"
                  classes={{ root: classes.checkbox }}
                />
              </ListItemIcon>
              <ListItemText
                className={classes.checkboxLabel}
                primary={t(`election_map_style_${name}`)}
              />
            </ListItem>
          ))}
        </List>
      </AccordionBox>
    </Box>
  );
});

StylePanel.defaultProps = {
  defaults: { water: true, parks: true, nature: false, roads: true, labels: false }
};

StylePanel.propTypes = {
  defaults: PropTypes.shape({
    water: PropTypes.bool.isRequired,
    parks: PropTypes.bool.isRequired,
    roads: PropTypes.bool.isRequired,
    nature: PropTypes.bool.isRequired,
    labels: PropTypes.bool.isRequired
  }),
  onChange: PropTypes.func,
  maxWidth: PropTypes.number
};

export default StylePanel;
