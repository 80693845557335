import { Box, FormControl, ListSubheader, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiInput-underline:before': {
      margin: 'auto',
      borderRadius: theme.borderRadius,
      width: '99.7%'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  select: {
    fontWeight: 500,
    textAlign: 'center',
    boxShadow: theme.shadows[6],
    padding: theme.spacing(1),
    borderRadius: theme.borderRadius,
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.borderRadius
    }
  },
  groupHeader: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    lineHeight: '25px',
    fontSize: '17px',
    fontWeight: 600,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1)
  },
  subGroupHeader: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    lineHeight: '25px',
    fontSize: '16px',
    top: '29px',
    paddingLeft: theme.spacing(2),
    fontWeight: 500
  },
  input: {
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.palette.borderColor,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.borderRadius,
    paddingLeft: ({ paddingLeft }) => paddingLeft
  },
  menuItem: {
    padding: theme.spacing(0.5, 0, 0.5, 3),
    fontSize: '15px',
    '& .menuItem': {
      fontSize: '15px'
    },
    '& .hiddenMenuItem': {
      display: 'none',
      marginRight: 0
    }
  },
  menuPaper: {
    maxHeight: 300,
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.palette.borderColor,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.background.paper
  }
}));

const EnhancedSelect = ({
  options,
  optionLabelKey,
  optionValueKey,
  groupKey,
  subGroupKey,
  onChange,
  value,
  selectedText,
  selectedIcon,
  paddingLeft,
  mainGroupOrderer
}) => {
  const classes = useStyles({ paddingLeft });

  const onSelect = useCallback((e) => onChange(e.target.value), [onChange]);

  const groupes = useMemo(
    () =>
      options.reduce((accumulator, option) => {
        accumulator[option[groupKey]] = {
          ...(accumulator[option[groupKey]] || {}),
          [option[subGroupKey]]: [
            ...((accumulator[option[groupKey]] || {})[option[subGroupKey]] || []),
            option
          ]
        };
        return accumulator;
      }, {}),
    [options, groupKey, subGroupKey]
  );

  const mainGroups = useMemo(
    () => Object.keys(groupes).sort(mainGroupOrderer),
    [groupes, mainGroupOrderer]
  );

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={Object.keys(groupes).length ? value : ''}
        onChange={onSelect}
        classes={{ root: classes.select }}
        inputProps={{
          className: classes.input
        }}
        MenuProps={{
          PaperProps: {
            className: classes.menuPaper
          },
          MenuListProps: {
            style: { padding: 0 }
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }}
      >
        {mainGroups.map((groupLabel) => [
          <ListSubheader key={groupLabel} color="primary" classes={{ sticky: classes.groupHeader }}>
            {groupLabel}
          </ListSubheader>,
          Object.keys(groupes[groupLabel])
            .sort((a, b) => b - a)
            .map((subGroupLabel) => [
              <ListSubheader
                key={`${groupLabel}_${subGroupLabel}`}
                color="primary"
                classes={{ sticky: classes.subGroupHeader }}
              >
                {subGroupLabel}
              </ListSubheader>,
              groupes[groupLabel][subGroupLabel].map((option) => (
                <MenuItem
                  key={option[optionValueKey]}
                  value={option[optionValueKey]}
                  className={classes.menuItem}
                >
                  <Box fontSize="18px" className="menuItem" overflow="hidden">
                    <Box display="inline-flex" alignItems="center" justifyContent="center">
                      {selectedIcon && (
                        <Box mr={1} className="hiddenMenuItem" display="flex">
                          {selectedIcon}
                        </Box>
                      )}
                      {selectedText && (
                        <Box mr={1} className="hiddenMenuItem">
                          {selectedText}
                        </Box>
                      )}
                      <Box className="hiddenMenuItem" mr={0.5}>
                        {option[subGroupKey]}
                      </Box>
                      {option[optionLabelKey]}
                    </Box>
                  </Box>
                </MenuItem>
              ))
            ])
        ])}
      </Select>
    </FormControl>
  );
};

EnhancedSelect.defaultProps = {
  paddingLeft: '0'
};

EnhancedSelect.propTypes = {
  options: PropTypes.array.isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  groupKey: PropTypes.string.isRequired,
  subGroupKey: PropTypes.string,
  selectedText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedIcon: PropTypes.node,
  paddingLeft: PropTypes.string,
  value: PropTypes.node
};

export default EnhancedSelect;
