import { store } from '@risingstack/react-easy-state';

import api from '../api';

const eventsStore = store({
  events: [],
  eventsLoading: true,
  votersLists: [],
  votersListsLoading: true,
  async fetchEvents() {
    eventsStore.eventsLoading = true;
    const { data } = await api.get('/events', {
      params: {
        enEventTypeName: ['Presidential', 'Parliamentary', 'Adjara SC', 'Local']
      }
    });
    eventsStore.events = [...data].sort((a, b) => new Date(b.event_date) - new Date(a.event_date));
    eventsStore.eventsLoading = false;
  },
  async fetchVotersLists() {
    eventsStore.votersListsLoading = true;
    const { data } = await api.get('/events', {
      params: {
        enEventTypeName: ['Voters List']
      }
    });
    eventsStore.votersLists = [...data].sort(
      (a, b) => new Date(b.event_date) - new Date(a.event_date)
    );
    eventsStore.votersListsLoading = false;
  },
  get eventsWithYear() {
    return eventsStore.events.map((event) => ({
      ...event,
      en_name: event.en_name.slice(5),
      ka_name: event.ka_name.slice(5),
      year: Number(event.event_date.substring(0, 4))
    }));
  },
  get categorizedEvents() {
    const categories = {
      presidential: [],
      parliamentary: [],
      adjara: [],
      local: []
    };
    eventsStore.events.forEach((event) => {
      switch (event.en_event_type_name) {
        case 'Presidential':
          categories.presidential.push(event);
          break;
        case 'Parliamentary':
          categories.parliamentary.push(event);
          break;
        case 'Adjara SC':
          categories.adjara.push(event);
          break;
        case 'Local':
          categories.local.push(event);
          break;
        default:
          break;
      }
    });
    return categories;
  },
  event(id) {
    return eventsStore.events.find((event) => event.event_id === id);
  },
  get latestParliamentaryPartyListElection() {
    return eventsStore.events.filter(
      (event) =>
        event.en_event_type_name === 'Parliamentary' &&
        event.en_name.toLowerCase().includes('party list')
    )[0];
  }
});

eventsStore.fetchEvents();
eventsStore.fetchVotersLists();

export default eventsStore;
