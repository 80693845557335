import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import booleanWithin from '@turf/boolean-within';
import clsx from 'clsx';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import MapGL, { Layer, Source } from 'react-map-gl';

import { EmptyGeoJson, LayerColors } from '../../../../constants';
import ElectionContext from '../../ElectionContext';
import ColoringInfo from './ColoringInfo';
import HoverInfo from './HoverInfo';
import ZoomButtons from './ZoomButtons';

const useStyles = makeStyles((theme) => ({
  map: {
    '& .mapboxgl-map': {
      borderRadius: theme.borderRadius
    }
  }
}));

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = view(
  ({
    currentDefaultViewport,
    width,
    height,
    mapStyle,
    scrollZoom,
    backToParent,
    disableFullScreen,
    toggleFullScreen,
    isFullScreen,
    ...rest
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const {
      rootIndicatorId,
      hoverInfo,
      setHoverInfo,
      emptyHoverInfo,
      collectionData,
      collectionLoading,
      parentShapeData,
      parentShapeDataLoading,
      changeParentShapeId,
      hoverLocked,
      lockHover,
      unlockHover,
      palette
    } = useContext(ElectionContext);
    const [viewport, setViewport] = useState(currentDefaultViewport);

    const onHover = useCallback(
      (event) => {
        let hoveredFeature;
        try {
          if (
            event.features &&
            event.features.length > 1 &&
            booleanWithin(event.features[1], event.features[0])
          ) {
            hoveredFeature = event.features[1];
          } else if (event.features) {
            hoveredFeature = event.features[0];
          }
        } catch (error) {
          hoveredFeature = event.features[0];
        }
        setHoverInfo(
          hoveredFeature
            ? {
                x: !event.srcEvent.offsetX ? event.srcEvent.layerX : event.srcEvent.offsetX,
                y: !event.srcEvent.offsetY ? event.srcEvent.layerY : event.srcEvent.offsetY,
                properties: Object.keys(hoveredFeature.properties).reduce((acc, propertyKey) => {
                  acc[propertyKey] =
                    hoveredFeature.properties[propertyKey] === 'null'
                      ? null
                      : hoveredFeature.properties[propertyKey];
                  return acc;
                }, {})
              }
            : null
        );
      },
      [setHoverInfo]
    );

    const onLayerClick = useCallback(
      async (event) => {
        if (hoverLocked) {
          unlockHover();
        } else {
          let feature;
          try {
            if (
              event.features &&
              event.features.length > 1 &&
              booleanWithin(event.features[1], event.features[0])
            ) {
              feature = event.features[1];
            } else if (event.features) {
              feature = event.features[0];
            }
          } catch (error) {
            feature = event.features[0];
          }
          if (feature) {
            if (
              !feature?.properties?.winner_root_indicator_id ||
              feature?.properties?.winner_root_indicator_id === 'null'
            ) {
              return;
            }
            if (
              !feature?.properties?.num_precincts ||
              feature?.properties?.num_precincts === 'null'
            ) {
              lockHover({
                x: !event.srcEvent.offsetX ? event.srcEvent.layerX : event.srcEvent.offsetX,
                y: !event.srcEvent.offsetY ? event.srcEvent.layerY : event.srcEvent.offsetY,
                properties: Object.keys(feature.properties).reduce((acc, propertyKey) => {
                  acc[propertyKey] =
                    feature.properties[propertyKey] === 'null'
                      ? null
                      : feature.properties[propertyKey];
                  return acc;
                }, {})
              });
            } else {
              changeParentShapeId(feature.properties.shape_id);
            }
          } else {
            backToParent();
          }
        }
      },
      [backToParent, changeParentShapeId, hoverLocked, lockHover, unlockHover]
    );

    const fillColor = useMemo(() => {
      if (rootIndicatorId != null && collectionData?.indicators?.[rootIndicatorId]?.scales) {
        const indicator = collectionData.indicators[rootIndicatorId];
        const scales = [...indicator.scales].sort((a, b) => a - b);
        return {
          property: rootIndicatorId,
          default: '#CCCCCC',
          stops: scales.map((number, index) => [
            number,
            LayerColors[palette][scales.length][index]
          ]),
          type: 'interval'
        };
      } else if (rootIndicatorId == null && collectionData?.indicators) {
        const resultsIndicatorRootIds = Object.keys(collectionData.indicators).filter(
          (rootIndicatorId) =>
            collectionData.indicators[rootIndicatorId].en_indicator_type_name === 'Results'
        );
        return resultsIndicatorRootIds.length
          ? {
              property: 'winner_root_indicator_id',
              default: '#CCCCCC',
              stops: resultsIndicatorRootIds.map((rootIndicatorId) => [
                Number(rootIndicatorId),
                collectionData.indicators[rootIndicatorId].color ||
                  theme.palette.defaultIndicatorColor
              ]),
              type: 'interval'
            }
          : '#CCCCCC';
      } else {
        return '#CCCCCC';
      }
    }, [collectionData?.indicators, rootIndicatorId, theme.palette.defaultIndicatorColor, palette]);

    return (
      <>
        <Box
          className={clsx(classes.map, 'joyride-Map')}
          height={height}
          width={width}
          onMouseLeave={emptyHoverInfo}
        >
          <MapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle={mapStyle}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            onViewportChange={setViewport}
            onHover={onHover}
            onClick={onLayerClick}
            interactiveLayerIds={
              !collectionLoading && collectionData?.collection ? ['collectionFill'] : []
            }
            attributionControl={false}
            scrollZoom={scrollZoom}
            {...rest}
          >
            <Source
              id="collection"
              type="geojson"
              data={
                !collectionLoading && collectionData?.collection
                  ? collectionData?.collection
                  : EmptyGeoJson
              }
            >
              <Layer
                // TODO: shape intersections make feature colors darker due to opacity.
                // See: http://localhost:3000/election?eventId=45&rootShapeId=89486&parentShapeId=89486
                // Issue: https://github.com/mapbox/mapbox-gl-js/issues/4090
                id="collectionFill"
                type="fill"
                paint={{
                  'fill-color': fillColor,
                  'fill-opacity': 0.7,
                  'fill-outline-color': '#000000'
                }}
              />
              {hoverInfo?.shape_id != null && (
                <Layer
                  id="collectionHighlight"
                  type="fill"
                  paint={{
                    'fill-color': fillColor,
                    'fill-opacity': 0.3
                  }}
                  filter={['==', 'shape_id', hoverInfo?.shape_id]}
                />
              )}
              {hoverInfo?.shape_id != null && (
                <Layer
                  id="collectionHighlightOutline"
                  type="line"
                  paint={{
                    'line-width': 1.4,
                    'line-color': '#000000'
                  }}
                  filter={['==', 'shape_id', hoverInfo?.shape_id]}
                />
              )}
            </Source>
            <Source
              id="parentShape"
              type="geojson"
              data={
                !parentShapeDataLoading && parentShapeData?.feature
                  ? parentShapeData?.feature
                  : EmptyGeoJson
              }
            >
              <Layer
                id="parentShapeLine"
                type="line"
                paint={{
                  'line-width': 1.5,
                  'line-color': '#000000',
                  'line-opacity': 0.5
                }}
              />
            </Source>
            <HoverInfo />
          </MapGL>
        </Box>
        <ColoringInfo fillColor={fillColor} rootIndicatorId={rootIndicatorId} />
        <ZoomButtons
          setViewport={setViewport}
          currentDefaultViewport={currentDefaultViewport}
          disableFullScreen={disableFullScreen}
          toggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
        />
      </>
    );
  }
);

export default Map;
