import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  summary: {
    whiteSpace: 'nowrap',
    minHeight: '0 !important',
    padding: theme.spacing(0, 1.5),
    '& .MuiIconButton-root': {
      padding: theme.spacing(0.75)
    }
  },
  summaryContent: {
    margin: '0 !important'
  },
  details: {
    borderTop: '1px solid',
    borderColor: theme.palette.borderColor,
    padding: 0,
    flexDirection: 'column'
  },
  accordionRoot: {
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    overflow: 'hidden',
    margin: '0 !important',
    '&.MuiAccordion-root:before': {
      height: 0
    }
  }
}));

const AccordionBox = ({ defaultExpanded = true, title, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Accordion
      component={Box}
      defaultExpanded={defaultExpanded}
      square
      elevation={0}
      classes={{
        root: classes.accordionRoot
      }}
      {...rest}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        component={Box}
        fontSize={16}
        fontWeight={500}
        className={classes.summary}
        classes={{ content: classes.summaryContent }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionBox;
