import { AppBar, Box, Button, Container, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  GetApp as GetAppIcon,
  Help as HelpIcon,
  HowToVote as HowToVoteIcon,
  Language as LanguageIcon,
  TrendingUp as TrendingUpIcon
} from '@material-ui/icons';
import { store, view } from '@risingstack/react-easy-state';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { path } from 'react-easy-params';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { tutorialStore } from '../globalStores';

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.background.header,
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[6]
  },
  tabs: {
    minHeight: '0'
  },
  links: {
    justifyContent: 'space-around'
  },
  indicator: {
    height: '100%',
    backgroundColor: 'transparent',
    borderTop: '3px solid #e90204',
    borderBottom: '3px solid #e90204'
  },
  root: {
    padding: theme.spacing(0.75, 0),
    flexGrow: 1
  },
  title: {
    fontWeight: 800,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap'
  },
  zoomButton: {
    transition: theme.transitionAll,
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  tutorialButton: {
    // padding: 0
  },
  link: {
    minWidth: 0,
    padding: theme.spacing(0.5, 1.75),
    fontSize: '18px',
    fontWeight: 600,
    minHeight: '0',
    borderRadius: '8px'
  },
  titleLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  headerIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = view(() => {
  const { t } = useTranslation();
  const classes = useStyles();

  switch (path[0]) {
    case undefined:
    case 'election':
    case 'elections':
      return (
        <Box
          component={Link}
          to="/elections"
          className={classes.titleLink}
          display="flex"
          alignItems="center"
        >
          <HowToVoteIcon style={{ marginRight: '8px' }} />
          {t('navigation_title_Elections')}
        </Box>
      );
    case 'parties':
    case 'party':
      return (
        <Box
          component={Link}
          to="/parties"
          className={classes.titleLink}
          display="flex"
          alignItems="center"
        >
          <TrendingUpIcon style={{ marginRight: '8px' }} />
          {t('navigation_title_Parties')}
        </Box>
      );
    case 'data':
      return (
        <Box display="flex" alignItems="center">
          <GetAppIcon style={{ marginRight: '8px' }} />
          {t('navigation_title_Data')}
        </Box>
      );
    default:
      return '';
  }
});

const Navbar = withWidth()(
  view(({ width }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isSmallScreen = useMemo(() => isWidthDown('md', width), [width]);

    const navbarStore = store({
      get tabValue() {
        switch (path[0]) {
          case undefined:
            return 'elections';
          case 'elections':
          case 'parties':
          case 'data':
            break;
          default:
            return false;
        }
        return path[0];
      },
      get isTutorialViewAvailable() {
        switch (path[0]) {
          case 'election':
          case 'party':
            return true;
          default:
            return false;
        }
      }
    });

    const changeLanguage = useCallback(
      () => (i18n.language === 'en' ? i18n.changeLanguage('ka') : i18n.changeLanguage('en')),
      [i18n]
    );

    const otherLanguageText = useMemo(
      () => (i18n.language === 'en' ? 'KA' : 'EN'),
      [i18n.language]
    );

    return (
      <AppBar color="inherit" className={classes.appbar}>
        <Container maxWidth="lg">
          <Grid container className={classes.root} alignItems="center" justify="space-between">
            {!isSmallScreen && (
              <>
                <Grid item xs={4}>
                  <Typography variant="h6" className={classes.title}>
                    <Box display="flex" alignItems="center">
                      <Header />
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Tabs
                    value={navbarStore.tabValue}
                    textColor="primary"
                    classes={{
                      root: classes.tabs,
                      flexContainer: classes.links,
                      indicator: classes.indicator
                    }}
                  >
                    <Tab
                      component={Link}
                      label={t('navigation_Elections')}
                      value="elections"
                      to="/elections"
                      className={clsx(classes.link, classes.zoomButton)}
                    />
                    <Tab
                      component={Link}
                      label={t('navigation_Parties')}
                      value="parties"
                      to="/parties"
                      className={clsx(classes.link, classes.zoomButton)}
                    />
                    <Tab
                      component={Link}
                      label={t('navigation_Data')}
                      value="data"
                      to="/data"
                      className={clsx(classes.link, classes.zoomButton)}
                    />
                  </Tabs>
                </Grid>
                {navbarStore.isTutorialViewAvailable && (
                  <Grid item xs="auto">
                    <Button
                      color="primary"
                      onClick={tutorialStore.runTutorial}
                      style={{ fontSize: '18px', marginRight: theme.spacing(0.5) }}
                      className={clsx(classes.zoomButton, classes.tutorialButton)}
                    >
                      <HelpIcon style={{ marginRight: theme.spacing(0.5) }} />
                      {t('navigation_Tutorial')}
                    </Button>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12} lg="auto" component={Box} textAlign="right">
              <Button
                color="primary"
                onClick={changeLanguage}
                style={{ fontSize: '18px' }}
                className={classes.zoomButton}
              >
                <LanguageIcon style={{ marginRight: theme.spacing(0.5) }} />
                {otherLanguageText}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    );
  })
);

export default Navbar;
