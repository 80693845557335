import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const ProgressChart = view(({ percentage, label, progressBgColor, vertical, ...rest }) => {
  const theme = useTheme();
  const data = useMemo(
    () => [
      {
        label,
        percentage: percentage > 100 ? 100 : percentage
      }
    ],
    [label, percentage]
  );

  const { width, height, layout, axis } = useMemo(
    () =>
      vertical
        ? {
            width: '30px',
            height: 1,
            layout: 'horizontal',
            axis: <YAxis ticks={[100]} hide />
          }
        : {
            width: 1,
            height: '30px',
            layout: 'vertical',
            axis: (
              <>
                <XAxis type="number" ticks={[100]} hide />
                <YAxis dataKey="label" type="category" hide />
              </>
            )
          },
    [vertical]
  );

  const fill = useMemo(
    () => progressBgColor || theme.palette.defaultIndicatorColor,
    [progressBgColor, theme.palette.defaultIndicatorColor]
  );

  return (
    <Box
      width={width}
      height={height}
      maxWidth="100%"
      borderRadius="2px"
      overflow="hidden"
      {...rest}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout={layout}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          barCategoryGap={0}
          style={{ cursor: 'inherit' }}
        >
          {axis}
          <Bar
            dataKey="percentage"
            fill={fill}
            stackId="a"
            animationBegin={0}
            animationDuration={300}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
});

ProgressChart.defaultProps = {
  vertical: false,
  label: ''
};

ProgressChart.propTypes = {
  percentage: PropTypes.number.isRequired,
  label: PropTypes.string,
  progressBgColor: PropTypes.string,
  vertical: PropTypes.bool
};

export default ProgressChart;
