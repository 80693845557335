import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { MultiSelect } from '../';

const ColumnSelector = ({ headCells, setSelectedColumns, selectedColumns }) => {
  const options = useMemo(
    () => Object.keys(headCells).reduce((acc, id) => ({ ...acc, [id]: headCells[id]?.label }), {}),
    [headCells]
  );

  return (
    <Box className="joyride-ColumnSelector">
      <MultiSelect options={options} setSelected={setSelectedColumns} selected={selectedColumns} />
    </Box>
  );
};

ColumnSelector.propTypes = {
  headCells: PropTypes.object.isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
  selectedColumns: PropTypes.array.isRequired
};

export default ColumnSelector;
