import { autoEffect, batch, store } from '@risingstack/react-easy-state';
import useAxios from 'axios-hooks';
import { useEffect } from 'react';
import { params } from 'react-easy-params';

import { eventsStore } from '../globalStores';

export default function useElectionData({
  eventIdKey,
  parentShapeIdKey,
  rootShapeIdKey,
  rootIndicatorIdKey,
  paletteKey,
  defaultRootIndicators
}) {
  const [, fetchCollection] = useAxios({ url: '/collection' }, { manual: true });
  const [, fetchParent] = useAxios({ url: '/shape' }, { manual: true });

  const electionStore = store({
    hoverInfo: null,
    hoverPosition: null,
    hoverLocked: false,
    lockHover: (info) => {
      electionStore.hoverLocked = true;
      const { x, y, properties } = info;
      electionStore.hoverPosition = { x, y };
      if (properties.shape_id !== electionStore.hoverInfo?.shape_id) {
        electionStore.hoverInfo = properties;
      }
    },
    unlockHover: () => {
      electionStore.hoverLocked = false;
    },
    setHoverInfo: (info) => {
      if (!electionStore.hoverLocked) {
        if (info == null) {
          electionStore.hoverInfo = null;
        } else {
          const { x, y, properties } = info;
          electionStore.hoverPosition = { x, y };
          if (properties.shape_id !== electionStore.hoverInfo?.shape_id) {
            electionStore.hoverInfo = properties;
          }
        }
      }
    },
    emptyHoverInfo: () => {
      if (!electionStore.hoverLocked) {
        electionStore.hoverInfo = null;
      }
    },

    eventIdKey,
    get parentShapeId() {
      return params[parentShapeIdKey];
    },
    get rootShapeId() {
      return params[rootShapeIdKey];
    },
    get rootIndicatorId() {
      return params[rootIndicatorIdKey];
    },
    get eventId() {
      return params[eventIdKey];
    },

    get palette() {
      return params[paletteKey] || 'red';
    },
    changePalette(palette) {
      params[paletteKey] = palette;
    },

    collectionLoading: true,
    async fetchCollection() {
      electionStore.collectionLoading = true;
      const { data } = await fetchCollection(
        {
          params: { parentShapeId: electionStore.parentShapeId, eventId: electionStore.eventId }
        },
        { useCache: true }
      );
      batch(() => {
        electionStore.collectionData = data;
        electionStore.collectionLoading = false;
      });
    },

    parentShapeDataLoading: true,
    async fetchParent() {
      electionStore.hoverLocked = false;
      electionStore.hoverInfo = null;
      electionStore.parentShapeDataLoading = true;
      const { data } = await fetchParent(
        {
          params: { shapeId: electionStore.parentShapeId, eventId: electionStore.eventId }
        },
        { useCache: true }
      );
      electionStore.parentShapeData = data;
      electionStore.parentShapeDataLoading = false;
    },

    get loading() {
      return electionStore.parentShapeDataLoading || electionStore.collectionLoading;
    },

    async fetchData() {
      electionStore.fetchParent();
      electionStore.fetchCollection();
    },

    get event() {
      const usedEvent = eventsStore.event(parseInt(electionStore.eventId));
      return {
        ...usedEvent,
        en_name: usedEvent?.en_name.slice(5),
        ka_name: usedEvent?.ka_name.slice(5),
        year: usedEvent?.event_date.substring(0, 4)
      };
    },
    get relatedEvents() {
      const eventsWithSameType = eventsStore.events
        .map((event) => ({
          ...event,
          en_name: event.en_name.slice(5),
          ka_name: event.ka_name.slice(5),
          year: event.event_date.substring(0, 4)
        }))
        .filter(
          (e) =>
            e.en_event_type_name === electionStore.event.en_event_type_name &&
            e.event_id !== parseInt(electionStore.eventId)
        );
      const eventsWithSameTypeAndYear = eventsWithSameType.filter(
        (e) => e.year === electionStore.event.year
      );
      if (eventsWithSameTypeAndYear.length === 0) {
        return eventsWithSameType
          .filter((e) => e.en_name === electionStore.event.en_name)
          .slice(0, 2);
      }
      if (eventsWithSameTypeAndYear.length > 2) {
        return eventsWithSameTypeAndYear.slice(0, 2);
      }
      return eventsWithSameTypeAndYear;
    },

    defaultRootIndicators,
    changeParentShapeId(newParentShapeId) {
      electionStore.setHoverInfo(null);
      params[parentShapeIdKey] = newParentShapeId;
    },
    changeEventId(newEventId) {
      electionStore.setHoverInfo(null);
      const event = eventsStore.event(newEventId);
      if (event) {
        batch(() => {
          params[eventIdKey] = event.event_id;
          params[rootShapeIdKey] = event.root_shape_id;
          params[parentShapeIdKey] = event.root_shape_id;
          params[rootIndicatorIdKey] = defaultRootIndicators
            ? electionStore.defaultRootIndicators[event.event_id].root_indicator_id.toString()
            : null;
        });
      }
    },
    changeRootIndicatorId(rootIndicatorId) {
      params[rootIndicatorIdKey] = rootIndicatorId;
    },

    get categorizedIndicators() {
      return Object.keys(electionStore.collectionData?.indicators || {}).reduce(
        (indicatorsAcc, rootIndicatorId) => {
          if (
            electionStore.collectionData?.indicators[rootIndicatorId].scales.length &&
            electionStore.collectionData?.indicators[rootIndicatorId].visible
          ) {
            const indicatorsKey =
              electionStore.collectionData?.indicators[rootIndicatorId].en_indicator_type_name ===
              'Other'
                ? 'otherIndicators'
                : 'resultsIndicators';
            return {
              ...indicatorsAcc,
              [indicatorsKey]: [
                ...indicatorsAcc[indicatorsKey],
                {
                  ...electionStore.collectionData?.indicators[rootIndicatorId],
                  root_indicator_id: rootIndicatorId
                }
              ]
            };
          }
          return indicatorsAcc;
        },
        { otherIndicators: [], resultsIndicators: [] }
      );
    },
    get sortedOtherIndicators() {
      const otherPrecedence = {
        'Total Turnout (%)': 1,
        'Total Turnout (#)': 2,
        'Total Eligible Voters': 3,
        'Turnout of female voters (%)': 4,
        'Turnout of male voters (%)': 5,
        'Turnout of female voters (#)': 6,
        'Turnout of male voters (#)': 7,
        'Eligible female voters': 8,
        'Eligible male voters': 9
      };
      return [...electionStore.categorizedIndicators.otherIndicators].sort(
        ({ en_core_name_abbrv: aName }, { en_core_name_abbrv: bName }) =>
          (otherPrecedence[aName] || 10) < (otherPrecedence[bName] || 10) ? -1 : 1
      );
    },
    get parentSortedResultsIndicators() {
      if (!electionStore.parentShapeData?.feature?.properties) {
        return electionStore.categorizedIndicators.resultsIndicators;
      }
      const properties = electionStore.parentShapeData.feature.properties;
      return [...electionStore.categorizedIndicators.resultsIndicators].sort(
        ({ root_indicator_id: aRootIndicatorId }, { root_indicator_id: bRootIndicatorId }) =>
          (properties[aRootIndicatorId] || 0) > (properties[bRootIndicatorId] || 0) ? -1 : 1
      );
    },
    get hoverSortedResultsIndicators() {
      if (!electionStore.showHovered) {
        return electionStore.parentSortedResultsIndicators;
      }
      const properties = electionStore.hoverInfo;
      return [...electionStore.categorizedIndicators.resultsIndicators].sort(
        ({ root_indicator_id: aRootIndicatorId }, { root_indicator_id: bRootIndicatorId }) =>
          (properties[aRootIndicatorId] || 0) > (properties[bRootIndicatorId] || 0) ? -1 : 1
      );
    },
    get selectedIndicator() {
      return electionStore.collectionData?.indicators[electionStore.rootIndicatorId];
    },
    get biggestResultPercentage() {
      return electionStore.showHovered
        ? electionStore.hoverInfo[electionStore.hoverInfo.winner_root_indicator_id]
        : electionStore.parentShapeData?.feature?.properties[
            electionStore.parentShapeData?.feature?.properties?.winner_root_indicator_id
          ];
    },
    get showHovered() {
      return !!electionStore.hoverInfo?.winner_root_indicator_id;
    }
  });

  autoEffect(() => {
    if (electionStore.eventId && electionStore.parentShapeId) {
      electionStore.fetchData();
    }
  });

  useEffect(() => {
    if (defaultRootIndicators) {
      electionStore.defaultRootIndicators = defaultRootIndicators;
    }
  }, [defaultRootIndicators, electionStore]);

  return electionStore;
}
