import { Box, Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { view } from '@risingstack/react-easy-state';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React, { useEffect, useMemo } from 'react';
import { path } from 'react-easy-params';
import { initReactI18next } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { Footer, Navbar, SmallScreenWarning } from './components';
import { localizedApiKeysStore as apiKeys } from './globalStores';
import { useEasyParams } from './hooks';
import { Data, Election, ElectionCompare, Elections, Parties, Party } from './routes';
import theme from './theme';
import enTranslation from './translations/en.json';
import kaTranslation from './translations/ka.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      ka: { translation: kaTranslation }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

const RouterSwitch = view(() => {
  useEasyParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (path[0] === 'parties') {
    return <Parties />;
  }
  if (path[0] === 'party') {
    return <Party />;
  }
  if (path[0] === 'data') {
    return <Data />;
  }
  if (path[0] === 'election' && path[1] === 'compare') {
    return <ElectionCompare />;
  }
  if (path[0] === 'election') {
    return <Election />;
  }
  return <Elections />;
});

const ApiKeyTranslations = view(() => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'en' && apiKeys.language !== 'en') {
      apiKeys.language = 'en';
    } else if (i18n.language === 'ka' && apiKeys.language !== 'ka') {
      apiKeys.language = 'ka';
    }
  }, [i18n.language]);

  return null;
});

const ScreenSwitch = withWidth()(({ width }) => {
  const isSmallScreen = useMemo(() => isWidthDown('md', width), [width]);

  return isSmallScreen ? (
    <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="space-between">
      <Box mt={9.5}>
        <SmallScreenWarning />
      </Box>
    </Box>
  ) : (
    <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="space-between">
      <Box mt={9.5}>
        <Container maxWidth="lg">
          <ApiKeyTranslations />
          <RouterSwitch />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <ScreenSwitch />
      </Router>
    </ThemeProvider>
  );
};

export default App;
