import { autoEffect, store } from '@risingstack/react-easy-state';

import api from '../api';
import eventsStore from './eventsStore';

const partiesStore = store({
  parties: [],
  partiesById: {},
  partiesLoading: true,
  async fetchParties() {
    partiesStore.partiesLoading = true;
    const { data } = await api.get('/parties');
    const parties = data.map((party) => ({ ...party, to: `/party/${party.party_id}` }));
    partiesStore.parties = parties;
    partiesStore.partiesById = parties.reduce(
      (obj, party) => Object.assign(obj, { [party.party_id]: party }),
      {}
    );
    partiesStore.partiesLoading = false;
  },
  party(id) {
    return partiesStore.partiesById[id];
  },

  latestElectionLoading: true,
  latestElectionData: null,
  async fetchLatestElection() {
    partiesStore.latestElectionLoading = true;
    const { data } = await api.get('/shape', {
      params: {
        shapeId: eventsStore.latestParliamentaryPartyListElection.root_shape_id,
        eventId: eventsStore.latestParliamentaryPartyListElection.event_id
      }
    });
    partiesStore.latestElectionData = data;
    partiesStore.latestElectionLoading = false;
  },

  get latestPartyRows() {
    return Object.keys(partiesStore.latestElectionData?.indicators || {})
      .reduce((indicatorsAcc, rootIndicatorId) => {
        if (
          partiesStore.latestElectionData.indicators[rootIndicatorId].visible &&
          partiesStore.latestElectionData.indicators[rootIndicatorId].party_id != null &&
          partiesStore.latestElectionData.indicators[rootIndicatorId].en_indicator_type_name ===
            'Results'
        ) {
          indicatorsAcc.push({
            ...partiesStore.latestElectionData.indicators[rootIndicatorId],
            root_indicator_id: rootIndicatorId,
            result: partiesStore.latestElectionData.feature.properties[rootIndicatorId],
            to: `/party/${partiesStore.latestElectionData.indicators[rootIndicatorId].party_id}`
          });
        }
        return indicatorsAcc;
      }, [])
      .sort(({ result: aResult }, { result: bResult }) =>
        (aResult || 0) > (bResult || 0) ? -1 : 1
      )
      .map((indicator, i) => ({ ...indicator, rank: i + 1 }));
  }
});

autoEffect(() => {
  if (eventsStore.latestParliamentaryPartyListElection) {
    partiesStore.fetchLatestElection();
  }
});

partiesStore.fetchParties();

export default partiesStore;
