import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon, People as PeopleIcon, PieChart as PieChartIcon } from '@material-ui/icons';
import { store, view } from '@risingstack/react-easy-state';
import React from 'react';
import { params, path } from 'react-easy-params';
import { useTranslation } from 'react-i18next';

import { TitledPaper } from '../../components';
import { eventsStore, localizedApiKeysStore as apiKeys, partiesStore } from '../../globalStores';
import { useElectionsStore } from '../../hooks';
import { IMGPartyPlaceholder } from '../../images';
import { PartyLineChart } from '../Election/components';
import { LatestChart, PartyElectionMap, PartyJoyride, PartyTable } from './components';

const useStyles = makeStyles((theme) => ({
  partyImg: {
    borderRadius: theme.borderRadius,
    width: '100%'
  }
}));

const Party = view(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const partyStore = store({
    get partyId() {
      return Number(path[1]);
    },
    get party() {
      return partiesStore.party(partyStore.partyId);
    },
    get eventsParticipated() {
      if (partyStore.party) {
        return Object.keys(partyStore.party.events).map((id) => Number(id));
      }
      return [];
    },
    get latestElections() {
      const latests = [];
      if (partyStore.eventsParticipated.length) {
        Object.keys(eventsStore.categorizedEvents).forEach((category) => {
          const latest = eventsStore.categorizedEvents[category].find((event) =>
            partyStore.eventsParticipated.includes(event.event_id)
          );
          if (latest) {
            latests.push({
              ...latest,
              root_indicator_id: partyStore.party.events[latest.event_id].root_indicator_id
            });
          }
        });
      }
      return latests.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
    },
    get defaultElection() {
      if (partyStore.eventsParticipated.length) {
        const latestEvent = eventsStore.events.find((event) =>
          partyStore.eventsParticipated.includes(event.event_id)
        );
        if (latestEvent) {
          return {
            ...latestEvent,
            root_indicator_id: partyStore.party.events[latestEvent.event_id].root_indicator_id
          };
        }
      }
      return null;
    }
  });

  const electionsStore = useElectionsStore({
    defaultElection: partyStore.defaultElection,
    initElections: partyStore.latestElections,
    copyIndicator: true
  });

  if (!partyStore.party) {
    return (
      <Box display="flex" justifyContent="center" mt={20}>
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  return (
    <>
      <PartyJoyride />
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <TitledPaper
            className="joyride-PartyDetails"
            fontSize={16}
            title={
              <Box display="flex" alignItems="center" justifyContent="center">
                <PeopleIcon fontSize="small" />
                <Box ml={1}>{t('party_details')}</Box>
              </Box>
            }
          >
            <Grid container alignItems="center" style={{ height: '100%' }}>
              <Grid item xs={6} component={Box} p={2}>
                <img
                  src={partyStore.party.party_logo_url || IMGPartyPlaceholder}
                  alt="PartyLogo"
                  className={classes.partyImg}
                />
              </Grid>
              <Grid item xs={6} component={Box} height={1}>
                <Box
                  p={3}
                  display="flex"
                  height={1}
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box mb={2}>
                      <Box fontWeight={500} mr={0.5}>
                        {t('party_name_abbrv')}:
                      </Box>
                      {partyStore.party[apiKeys.indicatorCoreNameKey]}
                    </Box>
                    <Box mb={2}>
                      <Box fontWeight={500} mr={0.5}>
                        {t('party_name')}:
                      </Box>
                      {partyStore.party[apiKeys.indicatorCoreFullNameKey]}
                    </Box>
                    <Box mb={2}>
                      <Box fontWeight={500} mr={0.5}>
                        {t('party_year_of_establishment')}:
                      </Box>
                      {partyStore.party.year_of_establishment || t('unknown')}
                    </Box>
                  </Box>
                  <Box textAlign="right">
                    {t('party_elections_count')}
                    <Box display="inline" fontWeight={500} mx={0.5}>
                      {Object.keys(partyStore.party.events).length}
                    </Box>
                    {t('party_elections')}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TitledPaper>
        </Grid>
        <Grid item xs={5}>
          <TitledPaper
            className="joyride-LatestCharts"
            title={
              <Box display="flex" alignItems="center" justifyContent="center">
                <PieChartIcon fontSize="small" />
                <Box ml={1}>{t('party_latest_results')}</Box>
              </Box>
            }
          >
            <Grid
              container
              spacing={1}
              component={Box}
              pb={1}
              px={2}
              fontSize={12}
              style={{ margin: 'auto' }}
            >
              {partyStore.latestElections.map((election) => (
                <Grid item xs={6} key={election.event_id} style={{ margin: 'auto' }}>
                  <LatestChart
                    color={partyStore.party.color}
                    result={partyStore.party.events[election.event_id].result}
                    label={partyStore.party[apiKeys.indicatorCoreNameKey]}
                    radius={230}
                  />
                  <Box pt={1} fontWeight={500} textAlign="center">{`Latest ${
                    election[apiKeys.eventTypeKey]
                  } (${partyStore.party.events[election.event_id].result.toFixed(2)}%)`}</Box>
                  <Box textAlign="center">{election[apiKeys.eventNameKey]}</Box>
                </Grid>
              ))}
            </Grid>
          </TitledPaper>
        </Grid>
      </Grid>
      <Box mt={1.5}>
        <Grid container spacing={3} className="joyride-PartyMaps">
          {(params.ids || []).map((id) => (
            <Grid item xs={6} key={id}>
              <PartyElectionMap electionsStore={electionsStore} partyStore={partyStore} id={id} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={3} mb={4} display="flex" justifyContent="center">
        <Button
          variant="outlined"
          onClick={electionsStore.createElection}
          disabled={!partyStore.latestElections.length}
          className="joyride-AddElectionButton"
        >
          <AddIcon fontSize="small" />
          {t('party_add_election')}
        </Button>
      </Box>
      <Box my={4}>
        <PartyLineChart
          electionsStore={electionsStore}
          width={1180}
          height={460}
          paddingX={{ left: 150, right: 190 }}
          persistentParty={partyStore.party}
        />
      </Box>
      <PartyTable electionsStore={electionsStore} persistentParty={partyStore.party} />
    </>
  );
});

export default Party;
