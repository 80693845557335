import { view } from '@risingstack/react-easy-state';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Joyride } from '../../../components';

const electionSteps = [
  'ElectionSelector',
  'Map',
  'ControlPanel',
  'ControlButtons',
  'StylePanel',
  'IndicatorPanel',
  'CompareButton',
  'TurnoutChart',
  'ParliamentaryChart',
  'RelatedElections',
  'SummaryTable',
  'TableHeadSort',
  'TableSearch',
  'Pagination',
  'DetailsTable',
  'ColumnSelector',
  'CSVButton'
];

const ElectionJoyride = view(() => {
  const { t } = useTranslation();

  const steps = useMemo(
    () =>
      electionSteps.map((step) => ({
        title: t(`joyride_election_title_${step}`),
        content: t(`joyride_election_${step}`),
        target: `.joyride-${step}`,
        disableBeacon: true
      })),
    [t]
  );

  return <Joyride steps={steps} />;
});

export default ElectionJoyride;
