import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

const useStyles = makeStyles(() => ({
  halfChart: { height: ({ radius }) => `${radius / 2}px !important` }
}));

const LatestChart = view(({ radius, color, result, label }) => {
  const theme = useTheme();
  const classes = useStyles({ radius });
  const { t } = useTranslation();

  const data = useMemo(
    () => [
      {
        value: result,
        color,
        name: label
      },
      {
        value: 100 - result,
        color: '#f4f4f4',
        name: t('party_other')
      }
    ],
    [color, label, result, t]
  );

  return (
    <PieChart width={radius} height={radius} className={classes.halfChart}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        outerRadius="100%"
        startAngle={180}
        endAngle={0}
        animationBegin={0}
        animationDuration={300}
      >
        {data.map((entry) => (
          <Cell key={entry.name} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip
        formatter={(value) => `${value.toFixed(2)}%`}
        contentStyle={{
          borderRadius: theme.borderRadius,
          boxShadow: theme.shadows[6],
          borderColor: theme.palette.borderColor
        }}
      />
    </PieChart>
  );
});

LatestChart.defaultProps = {
  radius: 300
};

LatestChart.propTypes = {
  radius: PropTypes.number
};

export default LatestChart;
