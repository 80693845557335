import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Check as CheckIcon, Palette as PaletteIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionBox } from '../../../components';
import { LayerColors } from '../../../constants';
import ElectionContext from '../ElectionContext';

const useStyles = makeStyles((theme) => ({
  color: {
    width: '20px',
    height: '20px',
    boxShadow: theme.shadows[2],
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '22px',
    transition: theme.transitionAll,
    '&:hover': {
      transform: 'scale(1.15)'
    }
  }
}));

const PalettePanel = view(({ maxWidth, defaultExpanded = true }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { palette, changePalette } = useContext(ElectionContext);

  return (
    <Box maxWidth={maxWidth} className="joyride-PalettePanel">
      <AccordionBox
        defaultExpanded={defaultExpanded}
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <PaletteIcon fontSize="small" />
            <Box ml={0.5}>{t('election_palette_selector')}</Box>
          </Box>
        }
      >
        <Box px={1} py={1.5}>
          <Grid container>
            {Object.keys(LayerColors).map((color) => (
              <Grid item xs={2} key={color}>
                <Box display="flex" justifyContent="center">
                  <Box
                    bgcolor={LayerColors[color][1][0]}
                    onClick={() => changePalette(color)}
                    className={classes.color}
                  >
                    {color === palette && <CheckIcon color="primary" fontSize="inherit" />}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </AccordionBox>
    </Box>
  );
});

export default PalettePanel;
