import { Box, Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import React, { useCallback, useMemo } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';

import { tutorialStore } from '../globalStores';
import { useWindowSize } from '../hooks';

/*
  joyride-ColumnSelector
  joyride-Pagination
  joyride-TableHeadSort
  joyride-TableSearch
  joyride-CSVButton
  joyride-AddElectionButton
  joyride-ControlPanel
  joyride-DetailsTable
  joyride-ElectionSelector
  joyride-IndicatorPanel
  joyride-Map
  joyride-ControlButtons
  joyride-CompareButton
  joyride-ParliamentaryChart
  joyride-RelatedElections
  joyride-PartyLineChart
  joyride-PartyLineChartSelector
  joyride-StylePanel
  joyride-SummaryTable
  joyride-SummaryCompareTable
  joyride-TurnoutChart
  joyride-PartyDetails
  joyride-LatestCharts
  joyride-PartyTable
  joyride-PartyMaps
*/

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px'
  }
}));

const JoyrideTooltip = ({
  index,
  step,
  tooltipProps,
  primaryProps,
  backProps,
  isLastStep,
  size
}) => {
  const classes = useStyles();

  return (
    <Card {...tooltipProps} className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {step.title}
        </Typography>
        <Box fontSize={16} whiteSpace="break-spaces">
          {step.content}
        </Box>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="space-between" alignItems="center" width={1}>
          <Box pl={1} fontWeight={500}>
            {index + 1} / {size}
          </Box>
          <Box>
            {index > 0 && (
              <Box mr={1} display="inline-block">
                <Button variant="outlined" {...backProps}>
                  Back
                </Button>
              </Box>
            )}
            <Button variant="outlined" {...primaryProps}>
              {isLastStep ? 'End' : 'Next'}
            </Button>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

const CustomJoyride = view(({ steps }) => {
  const windowSize = useWindowSize();

  const callback = useCallback((data) => {
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(data.status) ||
      [ACTIONS.CLOSE, ACTIONS.SKIP].includes(data.action)
    ) {
      tutorialStore.run = false;
    }
  }, []);

  const scrollOffset = useMemo(() => Math.round(windowSize.height / 2), [windowSize.height]);

  return (
    <Joyride
      steps={steps}
      run={tutorialStore.run}
      callback={callback}
      tooltipComponent={JoyrideTooltip}
      continuous={true}
      scrollToFirstStep={true}
      scrollOffset={scrollOffset}
      spotlightPadding={0}
      styles={{
        options: { zIndex: 10000 },
        spotlight: { borderRadius: '14px' }
      }}
    />
  );
});

export default CustomJoyride;
