import { Box } from '@material-ui/core';
import {
  FiberManualRecord as FiberManualRecordIcon,
  GridOn as GridOnIcon
} from '@material-ui/icons';
import { autoEffect, view } from '@risingstack/react-easy-state';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';
import ElectionContext from '../ElectionContext';

const ResultRenderer = ({ indicator, value }) => {
  if (value.winner_root_indicator_id === parseInt(indicator.root_indicator_id)) {
    return (
      <Box
        width={1}
        height={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight={800}
      >
        <FiberManualRecordIcon style={{ fill: indicator.color, marginRight: '10px' }} />
        <Box whiteSpace="nowrap">{value[indicator.root_indicator_id]?.toFixed(2)} %</Box>
      </Box>
    );
  }

  return (
    <Box width={1} height={1}>
      {value[indicator.root_indicator_id] != null
        ? `${value[indicator.root_indicator_id]?.toFixed(2)} %`
        : '-'}
    </Box>
  );
};

const DetailsTable = view(() => {
  const election = useContext(ElectionContext);
  const { t } = useTranslation();

  const rows = useMemo(
    () =>
      (election.collectionData?.collection?.features || [])
        .filter((feature) => feature.properties.winner_root_indicator_id)
        .map((feature) => feature.properties),
    [election.collectionData?.collection?.features]
  );

  const [headCells, setHeadCells] = useState({});
  const [defaultColumnsSelected, setDefaultColumnsSelected] = useState([]);
  autoEffect(() => {
    const defaultColumnsSelected = [apiKeys.shapeNameKey];
    const headCells = {
      [apiKeys.shapeNameKey]: {
        canOrder: true,
        orderBy: apiKeys.shapeNameKey,
        label: t('election_table_summary_name'),
        downloadable: true
      },
      ...election.parentSortedResultsIndicators.reduce((accumulator, indicator, i) => {
        if (i < 5) {
          defaultColumnsSelected.push(indicator.root_indicator_id);
        }
        return {
          ...accumulator,
          [indicator.root_indicator_id]: {
            canOrder: true,
            orderBy: indicator.root_indicator_id,
            label: `#${i + 1} ${indicator[apiKeys.indicatorCoreNameKey]}${
              indicator[apiKeys.indicatorParentCoreNameKey]
                ? `\n(${indicator[apiKeys.indicatorParentCoreNameKey]})`
                : ''
            }`,
            downloadable: true,
            Renderer: function ResultRendererOuter(value) {
              return <ResultRenderer indicator={indicator} value={value} />;
            }
          }
        };
      }, {}),
      ...election.categorizedIndicators.otherIndicators.reduce(
        (accumulator, indicator) => ({
          ...accumulator,
          [indicator.root_indicator_id]: {
            canOrder: true,
            orderBy: indicator.root_indicator_id,
            label: indicator[apiKeys.indicatorCoreNameKey],
            downloadable: true,
            Renderer: (value) =>
              value[indicator.root_indicator_id] != null
                ? indicator.number_format === '%'
                  ? `${value[indicator.root_indicator_id]} %`
                  : value[indicator.root_indicator_id]
                : ''
          }
        }),
        {}
      )
    };
    setHeadCells(headCells);
    setDefaultColumnsSelected(defaultColumnsSelected);
  });

  return (
    <Box className="joyride-DetailsTable">
      <Table
        rows={rows}
        headCells={headCells}
        defaultColumnsSelected={defaultColumnsSelected}
        defaultPagination={10}
        rowsPerPageOptions={[10, 20, 40, 60]}
        defaultOrderBy={apiKeys.shapeNameKey}
        gridStyle
        loading={election.loading}
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <GridOnIcon />
            <Box ml={1}>{`${t('election_table_builder')} ${(
              election.parentShapeData?.feature?.properties[apiKeys.shapeNameKey] || ''
            ).toUpperCase()}`}</Box>
          </Box>
        }
        my={4}
        searchBarFieldIds={[apiKeys.shapeNameKey]}
        idColumn={apiKeys.shapeNameKey}
        exporter
        columnSelector
      />
    </Box>
  );
});

export default DetailsTable;
