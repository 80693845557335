import { AppBar, Box, Grid, List, ListItem, ListItemText, Tab } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { autoEffect, view } from '@risingstack/react-easy-state';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressChart } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';
import ElectionContext from '../ElectionContext';
import OverallBarChart from './OverallBarChart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    overflow: 'hidden',
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: 'linear-gradient(90deg, rgba(226,250,201,1) 0%, rgba(137,239,224,1) 100%)',
      padding: theme.spacing(1.5)
    }
  },
  indicator: {
    display: 'none'
  },
  appbar: {
    boxShadow: 'none',
    zIndex: 1
  },
  tabList: {
    minHeight: 0,
    backgroundColor: '#ebebeb',
    borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`
  },
  tab: {
    width: '50%',
    minHeight: 0,
    minWidth: 0,
    maxWidth: '50%',
    padding: theme.spacing(0.5, 0),
    color: theme.palette.text.primary,
    borderBottom: '1px solid',
    borderColor: theme.palette.borderColor,
    opacity: 1
  },
  selectedTab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`,
    borderBottom: 'none'
  },
  selectedTabLeft: {
    borderRight: '1px solid'
  },
  selectedTabRight: {
    borderLeft: '1px solid'
  },
  listRoot: {
    width: '100%',
    padding: theme.spacing(1, 0)
  },
  listItemTextRoot: {
    margin: 0
  },
  button: {
    padding: theme.spacing(1, 1.5),
    transition: theme.transitionAll,
    '&:hover $indicatorName': {
      display: 'block'
    }
  },
  tabPanel: {
    padding: 0,
    width: '100%'
  },
  indicatorName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical'
  }
}));

const IndicatorSkeletons = ({ length = 5, progressBar }) => {
  const classes = useStyles();
  return (
    <>
      {[...Array(length)].map((e, i) => (
        <ListItem className={classes.button} key={i}>
          <ListItemText className={classes.listItemTextRoot}>
            <Grid
              component={Box}
              fontSize={14}
              container
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item xs={9}>
                <Skeleton variant="rect" width="100%" height="20px" animation="wave" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="rect" width="100%" height="20px" animation="wave" />
              </Grid>
              {progressBar && (
                <Grid item xs={12}>
                  <Skeleton variant="rect" width="100%" height="15px" animation="wave" />
                </Grid>
              )}
            </Grid>
          </ListItemText>
        </ListItem>
      ))}
    </>
  );
};

const Indicators = view(({ category }) => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    sortedOtherIndicators,
    hoverSortedResultsIndicators,
    biggestResultPercentage,
    showHovered,
    hoverInfo,
    parentShapeData,
    rootIndicatorId,
    changeRootIndicatorId,
    eventIdKey
  } = useContext(ElectionContext);

  const properties = useMemo(
    () => (showHovered ? hoverInfo : parentShapeData?.feature?.properties),
    [hoverInfo, parentShapeData?.feature?.properties, showHovered]
  );

  const indicators = useMemo(
    () => (category === 'Results' ? hoverSortedResultsIndicators : sortedOtherIndicators),
    [category, sortedOtherIndicators, hoverSortedResultsIndicators]
  );

  return (
    <>
      {indicators.map((indicator) => (
        <ListItem
          key={indicator.root_indicator_id}
          id={`${indicator.root_indicator_id}_${eventIdKey}`}
          button
          className={classes.button}
          selected={indicator.root_indicator_id === rootIndicatorId}
          onClick={() => changeRootIndicatorId(indicator.root_indicator_id)}
        >
          <ListItemText className={classes.listItemTextRoot}>
            <Grid component={Box} fontSize={14} container justifyContent="space-between">
              <Grid item xs={9} className={classes.indicatorName}>
                {indicator[apiKeys.indicatorCoreNameKey]}
                {indicator[apiKeys.indicatorParentCoreNameKey] &&
                  ` (${indicator[apiKeys.indicatorParentCoreNameKey]})`}
              </Grid>
              {properties?.[indicator.root_indicator_id] != null &&
                (indicator.number_format === '%' ? (
                  <>
                    <Grid item xs={3} component={Box} textAlign="right">
                      {`${properties[indicator.root_indicator_id].toFixed(2)}%`}
                    </Grid>
                    {properties[indicator.root_indicator_id] >= 1 && (
                      <ProgressChart
                        progressBgColor={indicator.color || theme.palette.defaultIndicatorColor}
                        percentage={
                          category === 'Results'
                            ? (100 * properties[indicator.root_indicator_id]) /
                              biggestResultPercentage
                            : properties[indicator.root_indicator_id]
                        }
                        height="15px"
                      />
                    )}
                  </>
                ) : (
                  <Grid item xs={3} component={Box} textAlign="right">
                    {properties[indicator.root_indicator_id]}
                  </Grid>
                ))}
            </Grid>
          </ListItemText>
        </ListItem>
      ))}
    </>
  );
});

const IndicatorPanel = view(({ width, resultSkeletonsLength, otherSkeletonsLength, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const electionStore = useContext(ElectionContext);

  const [selectedIndicatorType, setSelectedIndicatorType] = useState('Results');
  const changeIndicatorType = useCallback((event, newValue) => {
    setSelectedIndicatorType(newValue);
  }, []);

  // Initialize panel by selectedIndicator in the url query
  const [panelInitialized, setPanelInitialized] = useState(false);
  autoEffect(() => {
    if (!panelInitialized) {
      if (electionStore.rootIndicatorId === null) {
        setPanelInitialized(true);
      } else if (electionStore.selectedIndicator) {
        setSelectedIndicatorType(electionStore.selectedIndicator.en_indicator_type_name);
        setPanelInitialized(true);
        const indicatorItem = document.getElementById(
          `${electionStore.rootIndicatorId}_${electionStore.eventIdKey}`
        );
        if (indicatorItem) {
          const offsetTop = indicatorItem.offsetTop;
          const panel = document.getElementById(
            `indicators${electionStore.selectedIndicator.en_indicator_type_name}${electionStore.eventIdKey}`
          );
          if (panel) {
            panel.scrollTop = offsetTop - 20;
          }
        }
      }
    }
  }, [panelInitialized]);

  return (
    <Box width={width} className={clsx(classes.root, 'joyride-IndicatorPanel')}>
      <TabContext value={selectedIndicatorType}>
        <AppBar position="relative" color="transparent" className={classes.appbar}>
          <TabList
            classes={{ indicator: classes.indicator }}
            onChange={changeIndicatorType}
            className={classes.tabList}
          >
            <Tab
              label={t('election_map_indicators_results')}
              value="Results"
              className={classes.tab}
              classes={{ selected: clsx(classes.selectedTab, classes.selectedTabLeft) }}
            />
            <Tab
              label={t('election_map_indicators_other')}
              value="Other"
              className={classes.tab}
              classes={{ selected: clsx(classes.selectedTab, classes.selectedTabRight) }}
            />
          </TabList>
        </AppBar>
        <TabPanel value="Results" className={classes.tabPanel}>
          <Box id={`indicatorsResults${electionStore.eventIdKey}`} {...rest} overflow="auto">
            <List className={classes.listRoot} disablePadding>
              <ListItem
                key={name}
                button
                className={classes.button}
                selected={!electionStore.rootIndicatorId}
                onClick={() => electionStore.changeRootIndicatorId(null)}
              >
                <ListItemText className={classes.listItemTextRoot}>
                  <Box fontWeight={500}>{t('election_overall_results')}</Box>
                  <OverallBarChart />
                </ListItemText>
              </ListItem>
              {electionStore.loading ? (
                <IndicatorSkeletons progressBar length={resultSkeletonsLength} />
              ) : (
                <Indicators category="Results" />
              )}
            </List>
          </Box>
        </TabPanel>
        <TabPanel value="Other" className={classes.tabPanel}>
          <Box id={`indicatorsOther${electionStore.eventIdKey}`} {...rest} overflow="auto">
            <List className={classes.listRoot} disablePadding>
              {electionStore.loading ? (
                <IndicatorSkeletons length={otherSkeletonsLength || 9} />
              ) : (
                <Indicators category="Other" />
              )}
            </List>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
});

IndicatorPanel.defaultProps = {
  width: 260
};

IndicatorPanel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  resultSkeletonsLength: PropTypes.number,
  otherSkeletonsLength: PropTypes.number
};

export default IndicatorPanel;
