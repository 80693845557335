import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { view } from '@risingstack/react-easy-state';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    borderRadius: theme.borderRadius,
    height: '100%',
    width: '100%',
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.background.paper
  },
  title: {
    textTransform: 'uppercase',
    backgroundColor: theme.palette.background.header,
    borderRadius: `13px 13px 0 0`,
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 800,
    padding: theme.spacing(0.5),
    textAlign: 'center'
  }
}));

const TitledPaper = view(({ title, children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      {title && <Box className={classes.title}>{title}</Box>}
      {children}
    </Box>
  );
});

export default TitledPaper;
