import { Box, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CancelPresentation as CancelPresentationIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { TitledPaper } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';
import ElectionContext from '../ElectionContext';

/*
  Pie Chart.
*/

const useStyles = makeStyles(() => ({
  halfChart: { height: ({ radius }) => `${radius / 2}px !important` },
  partyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    '&:hover': {
      display: 'block'
    }
  }
}));

const ParliamentaryChart = view(({ radius, half }) => {
  const classes = useStyles({ radius });
  const theme = useTheme();
  const { t } = useTranslation();
  const { loading, hoverInfo, parentShapeData, hoverSortedResultsIndicators, showHovered } =
    useContext(ElectionContext);

  const parliamentaryData = useMemo(() => {
    if (loading) {
      return [];
    }
    const properties = showHovered ? hoverInfo : parentShapeData.feature.properties;
    return hoverSortedResultsIndicators
      .slice(0, 5)
      .filter((resultsIndicator) => properties[resultsIndicator.root_indicator_id])
      .map((resultsIndicator) => ({
        name: `${resultsIndicator[apiKeys.indicatorCoreNameKey]}${
          resultsIndicator[apiKeys.indicatorParentCoreNameKey]
            ? ` (${resultsIndicator[apiKeys.indicatorParentCoreNameKey]})`
            : ''
        }`,
        value: properties[resultsIndicator.root_indicator_id],
        color: resultsIndicator.color || theme.palette.defaultIndicatorColor
      }));
  }, [
    loading,
    showHovered,
    hoverInfo,
    parentShapeData?.feature?.properties,
    hoverSortedResultsIndicators,
    theme.palette.defaultIndicatorColor
  ]);

  return (
    <Box className="joyride-ParliamentaryChart" height={1}>
      <TitledPaper
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <CancelPresentationIcon fontSize="small" />
            <Box ml={1}>{`${t('election_chart_parliamentary')} ${
              (showHovered && hoverInfo?.[apiKeys.shapeNameKey]) ||
              parentShapeData?.feature?.properties[apiKeys.shapeNameKey]
            }`}</Box>
          </Box>
        }
      >
        {loading ? (
          <Skeleton variant="rect" width="100%" height="100%" animation="wave" />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" p={1.5} height={1}>
            <PieChart width={radius} height={radius} className={half && classes.halfChart}>
              <Pie
                data={parliamentaryData}
                dataKey="value"
                nameKey="name"
                outerRadius="100%"
                endAngle={half ? 180 : 360}
                animationBegin={0}
                animationDuration={300}
              >
                {parliamentaryData.map((entry) => (
                  <Cell key={entry.name} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) => `${value.toFixed(2)}%`}
                contentStyle={{
                  borderRadius: theme.borderRadius,
                  boxShadow: theme.shadows[6],
                  borderColor: theme.palette.borderColor
                }}
              />
            </PieChart>

            <Grid container component={Box} spacing={1} alignItems="center">
              {parliamentaryData.map(({ name, value, color }) => (
                <Grid
                  item
                  xs={12}
                  key={name}
                  component={Box}
                  display="flex"
                  style={{ alignItems: 'center' }}
                >
                  <Box
                    display="table"
                    width="20px"
                    height="20px"
                    bgcolor={color}
                    mx={1}
                    boxShadow={2}
                    borderRadius="2px"
                  />
                  <Box width={1} display="flex" justifyContent="space-between">
                    <Box className={classes.partyName}>{name}</Box>
                    <Box whiteSpace="nowrap" mx={1}>{`${value.toFixed(2)}%`}</Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </TitledPaper>
    </Box>
  );
});

ParliamentaryChart.defaultProps = {
  radius: 300,
  half: true
};

ParliamentaryChart.propTypes = {
  radius: PropTypes.number,
  half: PropTypes.bool
};

export default ParliamentaryChart;
