import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Group as GroupIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { view } from '@risingstack/react-easy-state';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { TitledPaper } from '../../../components';
import { localizedApiKeysStore as apiKeys } from '../../../globalStores';
import ElectionContext from '../ElectionContext';

const TurnoutChart = view(({ radius }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { collectionData, loading, hoverInfo, parentShapeData, showHovered } =
    useContext(ElectionContext);

  const turnoutData = useMemo(() => {
    if (loading) {
      return [];
    }
    const indicators = showHovered ? collectionData.indicators : parentShapeData.indicators;
    const percentageIndicator = Object.keys(indicators).find(
      (indicatorKey) => indicators[indicatorKey].en_core_name_abbrv === 'Total Turnout (%)'
    );
    const votersIndicator = Object.keys(indicators).find(
      (indicatorKey) => indicators[indicatorKey].en_core_name_abbrv === 'Total Turnout (#)'
    );
    const properties = showHovered ? hoverInfo : parentShapeData.feature.properties;
    return [
      {
        name: t('election_chart_turnout_votes'),
        voters: properties[votersIndicator],
        value: properties[percentageIndicator],
        color: '#4a7fb9'
      },
      {
        name: t('election_chart_turnout_not_voted'),
        value: 100 - properties[percentageIndicator],
        color: '#e8e8e8'
      }
    ];
  }, [
    t,
    collectionData?.indicators,
    loading,
    hoverInfo,
    showHovered,
    parentShapeData?.feature?.properties,
    parentShapeData?.indicators
  ]);

  return (
    <Box className="joyride-TurnoutChart" height={1}>
      <TitledPaper
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            <GroupIcon fontSize="small" />
            <Box ml={1}>{`${t('election_chart_turnout')} ${
              (showHovered && hoverInfo?.[apiKeys.shapeNameKey]) ||
              parentShapeData?.feature?.properties[apiKeys.shapeNameKey]
            }`}</Box>
          </Box>
        }
      >
        {loading ? (
          <Skeleton variant="rect" width="100%" height="100%" animation="wave" />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" p={1}>
            <PieChart width={radius} height={radius}>
              <Pie
                data={turnoutData}
                dataKey="value"
                nameKey="name"
                innerRadius="50%"
                outerRadius="100%"
                startAngle={90}
                endAngle={450}
                animationBegin={0}
                animationDuration={300}
              >
                {turnoutData.map((entry) => (
                  <Cell key={entry.name} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) => `${value.toFixed(2)}%`}
                contentStyle={{
                  borderRadius: theme.borderRadius,
                  boxShadow: theme.shadows[6],
                  borderColor: theme.palette.borderColor
                }}
              />
              {turnoutData[0]?.value && (
                <text
                  x="50%"
                  y="50%"
                  fill={theme.palette.text.primary}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontWeight="800"
                  fontSize={radius / 10}
                >
                  {`${turnoutData[0].value.toFixed(2)}%`}
                </text>
              )}
            </PieChart>
            <Box ml={1}>
              {turnoutData.map(({ name, voters, color }) => (
                <Box display="flex" mb={2} key={name} alignItems="center" height={1}>
                  <Box
                    width="20px"
                    height="20px"
                    bgcolor={color}
                    mr={1}
                    boxShadow={2}
                    display="table"
                    borderRadius="2px"
                  />
                  <Box>
                    {name}
                    {voters && `: ${voters}`}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </TitledPaper>
    </Box>
  );
});

TurnoutChart.defaultProps = {
  radius: 200
};

TurnoutChart.propTypes = {
  radius: PropTypes.number
};

export default TurnoutChart;
