import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

// ISSUE for using unstable_createMuiStrictModeTheme: https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831

const transitionAll = 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';
const shadows = [
  'none',
  '0px 2px 1px -1px rgba(0,0,0,0.15),0px 1px 1px 0px rgba(0,0,0,0.11),0px 1px 3px 0px rgba(0,0,0,0.09)',
  '0px 3px 1px -2px rgba(0,0,0,0.15),0px 2px 2px 0px rgba(0,0,0,0.11),0px 1px 5px 0px rgba(0,0,0,0.09)',
  '0px 3px 3px -2px rgba(0,0,0,0.15),0px 3px 4px 0px rgba(0,0,0,0.11),0px 1px 8px 0px rgba(0,0,0,0.09)',
  '0px 2px 4px -1px rgba(0,0,0,0.15),0px 4px 5px 0px rgba(0,0,0,0.11),0px 1px 10px 0px rgba(0,0,0,0.09)',
  '0px 3px 5px -1px rgba(0,0,0,0.15),0px 5px 8px 0px rgba(0,0,0,0.11),0px 1px 14px 0px rgba(0,0,0,0.09)',
  '0px 3px 5px -1px rgba(0,0,0,0.15),0px 6px 10px 0px rgba(0,0,0,0.11),0px 1px 18px 0px rgba(0,0,0,0.09)',
  '0px 4px 5px -2px rgba(0,0,0,0.15),0px 7px 10px 1px rgba(0,0,0,0.11),0px 2px 16px 1px rgba(0,0,0,0.09)',
  '0px 5px 5px -3px rgba(0,0,0,0.15),0px 8px 10px 1px rgba(0,0,0,0.11),0px 3px 14px 2px rgba(0,0,0,0.09)',
  '0px 5px 6px -3px rgba(0,0,0,0.15),0px 9px 12px 1px rgba(0,0,0,0.11),0px 3px 16px 2px rgba(0,0,0,0.09)',
  '0px 6px 6px -3px rgba(0,0,0,0.15),0px 10px 14px 1px rgba(0,0,0,0.11),0px 4px 18px 3px rgba(0,0,0,0.09)',
  '0px 6px 7px -4px rgba(0,0,0,0.15),0px 11px 15px 1px rgba(0,0,0,0.11),0px 4px 20px 3px rgba(0,0,0,0.09)',
  '0px 7px 8px -4px rgba(0,0,0,0.15),0px 12px 17px 2px rgba(0,0,0,0.11),0px 5px 22px 4px rgba(0,0,0,0.09)',
  '0px 7px 8px -4px rgba(0,0,0,0.15),0px 13px 19px 2px rgba(0,0,0,0.11),0px 5px 24px 4px rgba(0,0,0,0.09)',
  '0px 7px 9px -4px rgba(0,0,0,0.15),0px 14px 21px 2px rgba(0,0,0,0.11),0px 5px 26px 4px rgba(0,0,0,0.09)',
  '0px 8px 9px -5px rgba(0,0,0,0.15),0px 15px 22px 2px rgba(0,0,0,0.11),0px 6px 28px 5px rgba(0,0,0,0.09)',
  '0px 8px 10px -5px rgba(0,0,0,0.15),0px 16px 24px 2px rgba(0,0,0,0.11),0px 6px 30px 5px rgba(0,0,0,0.09)',
  '0px 8px 11px -5px rgba(0,0,0,0.15),0px 17px 26px 2px rgba(0,0,0,0.11),0px 6px 32px 5px rgba(0,0,0,0.09)',
  '0px 9px 11px -5px rgba(0,0,0,0.15),0px 18px 28px 2px rgba(0,0,0,0.11),0px 7px 34px 6px rgba(0,0,0,0.09)',
  '0px 9px 12px -6px rgba(0,0,0,0.15),0px 19px 29px 2px rgba(0,0,0,0.11),0px 7px 36px 6px rgba(0,0,0,0.09)',
  '0px 10px 13px -6px rgba(0,0,0,0.15),0px 20px 31px 3px rgba(0,0,0,0.11),0px 8px 38px 7px rgba(0,0,0,0.09)',
  '0px 10px 13px -6px rgba(0,0,0,0.15),0px 21px 33px 3px rgba(0,0,0,0.11),0px 8px 40px 7px rgba(0,0,0,0.09)',
  '0px 10px 14px -6px rgba(0,0,0,0.15),0px 22px 35px 3px rgba(0,0,0,0.11),0px 8px 42px 7px rgba(0,0,0,0.09)',
  '0px 11px 14px -7px rgba(0,0,0,0.15),0px 23px 36px 3px rgba(0,0,0,0.11),0px 9px 44px 8px rgba(0,0,0,0.09)',
  '0px 11px 15px -7px rgba(0,0,0,0.15),0px 24px 38px 3px rgba(0,0,0,0.11),0px 9px 46px 8px rgba(0,0,0,0.09)'
];

const theme = createMuiTheme({
  spacing: 8,
  shadows,
  palette: {
    borderColor: '#4e4e4e',
    text: {
      primary: '#4e4e4e',
      secondary: '#fff'
    },
    primary: {
      main: '#fff',
      contrastText: '#4e4e4e'
    },
    secondary: {
      main: '#000000',
      contrastText: '#fff'
    },
    background: {
      default: '#f4f4f4',
      paper: '#fff',
      header: '#000000'
    },
    defaultIndicatorColor: '#666666'
  },
  transitionAll,
  borderRadius: '14px',
  overrides: {
    MuiCardActionArea: {
      root: {
        backgroundColor: '#fff',
        boxShadow: shadows[3],
        transition: transitionAll,
        '&:hover': {
          boxShadow: shadows[6],
          transform: 'scale(1.02)'
        },
        '&:hover .MuiCardActionArea-focusHighlight': {
          opacity: 0
        }
      }
    },
    MuiButton: {
      outlined: {
        borderColor: '#4e4e4e',
        borderRadius: '14px',
        backgroundColor: '#fff',
        boxShadow: shadows[3],
        transition: transitionAll,
        '&:hover': {
          backgroundColor: '#f9f9f9',
          boxShadow: shadows[6],
          transform: 'scale(1.02)'
        }
      }
    }
  }
});

export default theme;
