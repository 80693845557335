import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Add as AddIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  GpsFixed as GpsFixedIcon,
  Remove as RemoveIcon
} from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';

import { ZOOM_AMOUNT, ZOOM_TRANSITION_DURATION } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  buttons: {
    position: 'absolute',
    right: '-1px',
    bottom: '-1px',
    zIndex: 2,
    padding: theme.spacing(0.5),
    display: 'flex',
    borderColor: theme.palette.borderColor,
    border: '1px solid',
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden'
  },
  zoomButton: {
    display: 'flex',
    minWidth: '0',
    width: '30px',
    height: '26px',
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0
  },
  leftBorder: {
    borderLeft: '1px solid'
  }
}));

const ZoomButtons = view(
  ({ setViewport, currentDefaultViewport, disableFullScreen, toggleFullScreen, isFullScreen }) => {
    const classes = useStyles();

    const zoomReset = useCallback(() => {
      setViewport({
        ...currentDefaultViewport,
        transitionDuration: ZOOM_TRANSITION_DURATION
      });
    }, [setViewport, currentDefaultViewport]);

    useEffect(() => {
      zoomReset();
    }, [zoomReset]);

    const zoomIn = useCallback(() => {
      setViewport((viewport) => ({
        ...viewport,
        zoom: viewport.zoom + ZOOM_AMOUNT,
        transitionDuration: ZOOM_TRANSITION_DURATION
      }));
    }, [setViewport]);

    const zoomOut = useCallback(() => {
      setViewport((viewport) => ({
        ...viewport,
        zoom: viewport.zoom - ZOOM_AMOUNT,
        transitionDuration: ZOOM_TRANSITION_DURATION
      }));
    }, [setViewport]);

    return (
      <Box className={clsx(classes.buttons, 'joyride-ControlButtons')}>
        <Button onClick={zoomIn} className={classes.zoomButton}>
          <AddIcon fontSize="small" />
        </Button>
        <Button onClick={zoomOut} className={clsx(classes.leftBorder, classes.zoomButton)}>
          <RemoveIcon fontSize="small" />
        </Button>
        <Button onClick={zoomReset} className={clsx(classes.leftBorder, classes.zoomButton)}>
          <GpsFixedIcon fontSize="small" />
        </Button>
        {!disableFullScreen && (
          <Button
            onClick={toggleFullScreen}
            className={clsx(classes.leftBorder, classes.zoomButton)}
          >
            {isFullScreen ? (
              <FullscreenExitIcon fontSize="small" />
            ) : (
              <FullscreenIcon fontSize="small" />
            )}
          </Button>
        )}
      </Box>
    );
  }
);

export default ZoomButtons;
