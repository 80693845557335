export default {
  red: {
    1: {
      0: '#e34a33'
    },
    2: {
      0: '#fdbb84',
      1: '#e34a33'
    },
    3: {
      0: '#fee8c8',
      1: '#fdbb84',
      2: '#e34a33'
    },
    4: {
      0: '#fef0d9',
      1: '#fdcc8a',
      2: '#fc8d59',
      3: '#d7301f'
    },
    5: {
      0: '#fef0d9',
      1: '#fdcc8a',
      2: '#fc8d59',
      3: '#e34a33',
      4: '#b30000'
    },
    6: {
      0: '#fef0d9',
      1: '#fdd49e',
      2: '#fdbb84',
      3: '#fc8d59',
      4: '#e34a33',
      5: '#b30000'
    },
    7: {
      0: '#fef0d9',
      1: '#fdd49e',
      2: '#fdbb84',
      3: '#fc8d59',
      4: '#ef6548',
      5: '#d7301f',
      6: '#990000'
    },
    8: {
      0: '#fff7ec',
      1: '#fee8c8',
      2: '#fdd49e',
      3: '#fdbb84',
      4: '#fc8d59',
      5: '#ef6548',
      6: '#d7301f',
      7: '#990000'
    },
    9: {
      0: '#fff7ec',
      1: '#fee8c8',
      2: '#fdd49e',
      3: '#fdbb84',
      4: '#fc8d59',
      5: '#ef6548',
      6: '#d7301f',
      7: '#b30000',
      8: '#7f0000'
    },
    10: {
      0: '#fff7ec',
      1: '#fee8c8',
      2: '#fdd49e',
      3: '#fdbb84',
      4: '#fc8d59',
      5: '#ef6548',
      6: '#d7301f',
      7: '#b30000',
      8: '#7f0000',
      9: '#400000' // 50
    },
    11: {
      0: '#fff7ec',
      1: '#fee8c8',
      2: '#fdd49e',
      3: '#fdbb84',
      4: '#fc8d59',
      5: '#ef6548',
      6: '#d7301f',
      7: '#b30000',
      8: '#7f0000',
      9: '#4c0000', // 40
      10: '#190000' // 80
    },
    12: {
      0: '#fff7ec',
      1: '#fee8c8',
      2: '#fdd49e',
      3: '#fdbb84',
      4: '#fc8d59',
      5: '#ef6548',
      6: '#d7301f',
      7: '#b30000',
      8: '#7f0000',
      9: '#590000', // 30
      10: '#330000', // 60
      11: '#0d0000' // 90
    },
    13: {
      0: '#fff7ec',
      1: '#fee8c8',
      2: '#fdd49e',
      3: '#fdbb84',
      4: '#fc8d59',
      5: '#ef6548',
      6: '#d7301f',
      7: '#b30000',
      8: '#7f0000',
      9: '#660000', // 20
      10: '#4c0000', // 40
      11: '#330000', // 60
      12: '#0d0000' // 90
    }
  },
  yellow: {
    1: {
      0: '#fe9929'
    },
    2: {
      0: '#fec44f',
      1: '#d95f0e'
    },
    3: {
      0: '#fff7bc',
      1: '#fec44f',
      2: '#d95f0e'
    },
    4: {
      0: '#ffffd4',
      1: '#fed98e',
      2: '#fe9929',
      3: '#cc4c02'
    },
    5: {
      0: '#ffffd4',
      1: '#fed98e',
      2: '#fe9929',
      3: '#d95f0e',
      4: '#993404'
    },
    6: {
      0: '#ffffd4',
      1: '#fee391',
      2: '#fec44f',
      3: '#fe9929',
      4: '#d95f0e',
      5: '#993404'
    },
    7: {
      0: '#ffffd4',
      1: '#fee391',
      2: '#fec44f',
      3: '#fe9929',
      4: '#ec7014',
      5: '#cc4c02',
      6: '#8c2d04'
    },
    8: {
      0: '#ffffe5',
      1: '#fff7bc',
      2: '#fee391',
      3: '#fec44f',
      4: '#fe9929',
      5: '#ec7014',
      6: '#cc4c02',
      7: '#8c2d04'
    },
    9: {
      0: '#ffffe5',
      1: '#fff7bc',
      2: '#fee391',
      3: '#fec44f',
      4: '#fe9929',
      5: '#ec7014',
      6: '#cc4c02',
      7: '#993404',
      8: '#662506'
    },
    10: {
      0: '#ffffe5',
      1: '#fff7bc',
      2: '#fee391',
      3: '#fec44f',
      4: '#fe9929',
      5: '#ec7014',
      6: '#cc4c02',
      7: '#993404',
      8: '#662506',
      9: '#331303'
    },
    11: {
      0: '#ffffe5',
      1: '#fff7bc',
      2: '#fee391',
      3: '#fec44f',
      4: '#fe9929',
      5: '#ec7014',
      6: '#cc4c02',
      7: '#993404',
      8: '#662506',
      9: '#3d1604',
      10: '#140701'
    },
    12: {
      0: '#ffffe5',
      1: '#fff7bc',
      2: '#fee391',
      3: '#fec44f',
      4: '#fe9929',
      5: '#ec7014',
      6: '#cc4c02',
      7: '#993404',
      8: '#662506',
      9: '#471a04',
      10: '#290f02',
      11: '#0a0401'
    },
    13: {
      0: '#ffffe5',
      1: '#fff7bc',
      2: '#fee391',
      3: '#fec44f',
      4: '#fe9929',
      5: '#ec7014',
      6: '#cc4c02',
      7: '#993404',
      8: '#662506',
      9: '#521e05',
      10: '#3d1604',
      11: '#290f02',
      12: '#0a0401'
    }
  },
  green: {
    1: {
      0: '#2ca25f'
    },
    2: {
      0: '#99d8c9',
      1: '#2ca25f'
    },
    3: {
      0: '#e5f5f9',
      1: '#99d8c9',
      2: '#2ca25f'
    },
    4: {
      0: '#edf8fb',
      1: '#b2e2e2',
      2: '#66c2a4',
      3: '#238b45'
    },
    5: {
      0: '#edf8fb',
      1: '#b2e2e2',
      2: '#66c2a4',
      3: '#2ca25f',
      4: '#006d2c'
    },
    6: {
      0: '#edf8fb',
      1: '#ccece6',
      2: '#99d8c9',
      3: '#66c2a4',
      4: '#2ca25f',
      5: '#006d2c'
    },
    7: {
      0: '#edf8fb',
      1: '#ccece6',
      2: '#99d8c9',
      3: '#66c2a4',
      4: '#41ae76',
      5: '#238b45',
      6: '#005824'
    },
    8: {
      0: '#f7fcfd',
      1: '#e5f5f9',
      2: '#ccece6',
      3: '#99d8c9',
      4: '#66c2a4',
      5: '#41ae76',
      6: '#238b45',
      7: '#005824'
    },
    9: {
      0: '#f7fcfd',
      1: '#e5f5f9',
      2: '#ccece6',
      3: '#99d8c9',
      4: '#66c2a4',
      5: '#41ae76',
      6: '#238b45',
      7: '#006d2c',
      8: '#00441b'
    },
    10: {
      0: '#f7fcfd',
      1: '#e5f5f9',
      2: '#ccece6',
      3: '#99d8c9',
      4: '#66c2a4',
      5: '#41ae76',
      6: '#238b45',
      7: '#006d2c',
      8: '#00441b',
      9: '#00220e'
    },
    11: {
      0: '#f7fcfd',
      1: '#e5f5f9',
      2: '#ccece6',
      3: '#99d8c9',
      4: '#66c2a4',
      5: '#41ae76',
      6: '#238b45',
      7: '#006d2c',
      8: '#00441b',
      9: '#002910',
      10: '#000e05'
    },
    12: {
      0: '#f7fcfd',
      1: '#e5f5f9',
      2: '#ccece6',
      3: '#99d8c9',
      4: '#66c2a4',
      5: '#41ae76',
      6: '#238b45',
      7: '#006d2c',
      8: '#00441b',
      9: '#003013',
      10: '#001b0b',
      11: '#000703'
    },
    13: {
      0: '#f7fcfd',
      1: '#e5f5f9',
      2: '#ccece6',
      3: '#99d8c9',
      4: '#66c2a4',
      5: '#41ae76',
      6: '#238b45',
      7: '#006d2c',
      8: '#00441b',
      9: '#003721',
      10: '#002919',
      11: '#001c10',
      12: '#000704'
    }
  },
  blue: {
    1: {
      0: '#2b8cbe'
    },
    2: {
      0: '#a6bddb',
      1: '#2b8cbe'
    },
    3: {
      0: '#ece7f2',
      1: '#a6bddb',
      2: '#2b8cbe'
    },
    4: {
      0: '#f1eef6',
      1: '#bdc9e1',
      2: '#74a9cf',
      3: '#0570b0'
    },
    5: {
      0: '#f1eef6',
      1: '#bdc9e1',
      2: '#74a9cf',
      3: '#2b8cbe',
      4: '#045a8d'
    },
    6: {
      0: '#f1eef6',
      1: '#d0d1e6',
      2: '#a6bddb',
      3: '#74a9cf',
      4: '#2b8cbe',
      5: '#045a8d'
    },
    7: {
      0: '#f1eef6',
      1: '#d0d1e6',
      2: '#a6bddb',
      3: '#74a9cf',
      4: '#3690c0',
      5: '#0570b0',
      6: '#034e7b'
    },
    8: {
      0: '#fff7fb',
      1: '#ece7f2',
      2: '#d0d1e6',
      3: '#a6bddb',
      4: '#74a9cf',
      5: '#3690c0',
      6: '#0570b0',
      7: '#034e7b'
    },
    9: {
      0: '#fff7fb',
      1: '#ece7f2',
      2: '#d0d1e6',
      3: '#a6bddb',
      4: '#74a9cf',
      5: '#3690c0',
      6: '#0570b0',
      7: '#045a8d',
      8: '#023858'
    },
    10: {
      0: '#fff7fb',
      1: '#ece7f2',
      2: '#d0d1e6',
      3: '#a6bddb',
      4: '#74a9cf',
      5: '#3690c0',
      6: '#0570b0',
      7: '#045a8d',
      8: '#023858',
      9: '#011c2c'
    },
    11: {
      0: '#fff7fb',
      1: '#ece7f2',
      2: '#d0d1e6',
      3: '#a6bddb',
      4: '#74a9cf',
      5: '#3690c0',
      6: '#0570b0',
      7: '#045a8d',
      8: '#023858',
      9: '#012235',
      10: '#000b12'
    },
    12: {
      0: '#fff7fb',
      1: '#ece7f2',
      2: '#d0d1e6',
      3: '#a6bddb',
      4: '#74a9cf',
      5: '#3690c0',
      6: '#0570b0',
      7: '#045a8d',
      8: '#023858',
      9: '#01273e',
      10: '#011623',
      11: '#000609'
    },
    13: {
      0: '#fff7fb',
      1: '#ece7f2',
      2: '#d0d1e6',
      3: '#a6bddb',
      4: '#74a9cf',
      5: '#3690c0',
      6: '#0570b0',
      7: '#045a8d',
      8: '#023858',
      9: '#022d46',
      10: '#012235',
      11: '#011623',
      12: '#000609'
    }
  },
  purple: {
    1: {
      0: '#8856a7'
    },
    2: {
      0: '#9ebcda',
      1: '#8856a7'
    },
    3: {
      0: '#e0ecf4',
      1: '#9ebcda',
      2: '#8856a7'
    },
    4: {
      0: '#edf8fb',
      1: '#b3cde3',
      2: '#8c96c6',
      3: '#88419d'
    },
    5: {
      0: '#edf8fb',
      1: '#b3cde3',
      2: '#8c96c6',
      3: '#8856a7',
      4: '#810f7c'
    },
    6: {
      0: '#edf8fb',
      1: '#bfd3e6',
      2: '#9ebcda',
      3: '#8c96c6',
      4: '#8856a7',
      5: '#810f7c'
    },
    7: {
      0: '#edf8fb',
      1: '#bfd3e6',
      2: '#9ebcda',
      3: '#8c96c6',
      4: '#8c6bb1',
      5: '#88419d',
      6: '#6e016b'
    },
    8: {
      0: '#f7fcfd',
      1: '#e0ecf4',
      2: '#bfd3e6',
      3: '#9ebcda',
      4: '#8c96c6',
      5: '#8c6bb1',
      6: '#88419d',
      7: '#6e016b'
    },
    9: {
      0: '#f7fcfd',
      1: '#e0ecf4',
      2: '#bfd3e6',
      3: '#9ebcda',
      4: '#8c96c6',
      5: '#8c6bb1',
      6: '#88419d',
      7: '#810f7c',
      8: '#4d004b'
    },
    10: {
      0: '#f7fcfd',
      1: '#e0ecf4',
      2: '#bfd3e6',
      3: '#9ebcda',
      4: '#8c96c6',
      5: '#8c6bb1',
      6: '#88419d',
      7: '#810f7c',
      8: '#4d004b',
      9: '#270026'
    },
    11: {
      0: '#f7fcfd',
      1: '#e0ecf4',
      2: '#bfd3e6',
      3: '#9ebcda',
      4: '#8c96c6',
      5: '#8c6bb1',
      6: '#88419d',
      7: '#810f7c',
      8: '#4d004b',
      9: '#2e002d',
      10: '#0f000f'
    },
    12: {
      0: '#f7fcfd',
      1: '#e0ecf4',
      2: '#bfd3e6',
      3: '#9ebcda',
      4: '#8c96c6',
      5: '#8c6bb1',
      6: '#88419d',
      7: '#810f7c',
      8: '#4d004b',
      9: '#360035',
      10: '#1f001e',
      11: '#080007'
    },
    13: {
      0: '#f7fcfd',
      1: '#e0ecf4',
      2: '#bfd3e6',
      3: '#9ebcda',
      4: '#8c96c6',
      5: '#8c6bb1',
      6: '#88419d',
      7: '#810f7c',
      8: '#4d004b',
      9: '#3e003c',
      10: '#2e002d',
      11: '#1f001e',
      12: '#080007'
    }
  },
  pink: {
    1: {
      0: '#dd1c77'
    },
    2: {
      0: '#c994c7',
      1: '#dd1c77'
    },
    3: {
      0: '#e7e1ef',
      1: '#c994c7',
      2: '#dd1c77'
    },
    4: {
      0: '#f1eef6',
      1: '#d7b5d8',
      2: '#df65b0',
      3: '#ce1256'
    },
    5: {
      0: '#f1eef6',
      1: '#d7b5d8',
      2: '#df65b0',
      3: '#dd1c77',
      4: '#980043'
    },
    6: {
      0: '#f1eef6',
      1: '#d4b9da',
      2: '#c994c7',
      3: '#df65b0',
      4: '#dd1c77',
      5: '#980043'
    },
    7: {
      0: '#f1eef6',
      1: '#d4b9da',
      2: '#c994c7',
      3: '#df65b0',
      4: '#e7298a',
      5: '#ce1256',
      6: '#91003f'
    },
    8: {
      0: '#f7f4f9',
      1: '#e7e1ef',
      2: '#d4b9da',
      3: '#c994c7',
      4: '#df65b0',
      5: '#e7298a',
      6: '#ce1256',
      7: '#91003f'
    },
    9: {
      0: '#f7f4f9',
      1: '#e7e1ef',
      2: '#d4b9da',
      3: '#c994c7',
      4: '#df65b0',
      5: '#e7298a',
      6: '#ce1256',
      7: '#980043',
      8: '#67001f'
    },
    10: {
      0: '#f7f4f9',
      1: '#e7e1ef',
      2: '#d4b9da',
      3: '#c994c7',
      4: '#df65b0',
      5: '#e7298a',
      6: '#ce1256',
      7: '#980043',
      8: '#67001f',
      9: '#340010'
    },
    11: {
      0: '#f7f4f9',
      1: '#e7e1ef',
      2: '#d4b9da',
      3: '#c994c7',
      4: '#df65b0',
      5: '#e7298a',
      6: '#ce1256',
      7: '#980043',
      8: '#67001f',
      9: '#3e0013',
      10: '#150006'
    },
    12: {
      0: '#f7f4f9',
      1: '#e7e1ef',
      2: '#d4b9da',
      3: '#c994c7',
      4: '#df65b0',
      5: '#e7298a',
      6: '#ce1256',
      7: '#980043',
      8: '#67001f',
      9: '#480016',
      10: '#29000c',
      11: '#0a0003'
    },
    13: {
      0: '#f7f4f9',
      1: '#e7e1ef',
      2: '#d4b9da',
      3: '#c994c7',
      4: '#df65b0',
      5: '#e7298a',
      6: '#ce1256',
      7: '#980043',
      8: '#67001f',
      9: '#520019',
      10: '#3e0013',
      11: '#29000c',
      12: '#0a0003'
    }
  }
};
