import { Box } from '@material-ui/core';
import { autoEffect, view } from '@risingstack/react-easy-state';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressChart, Table } from '../../../components';
import { eventsStore, localizedApiKeysStore as apiKeys, partiesStore } from '../../../globalStores';

const PercentageBarRenderer = (value) => {
  return (
    <ProgressChart
      percentage={value.result}
      progressBgColor={value.color}
      width="300px"
      height="20px"
      boxShadow={2}
      border={1}
    />
  );
};

const LatestElectionTable = view(() => {
  const { t } = useTranslation();

  const [headCells, setHeadCells] = useState({});
  autoEffect(() => {
    setHeadCells({
      rank: {
        canOrder: true,
        orderBy: 'rank',
        label: t('parties_latest_column_rank'),
        Renderer: (value) => `#${value.rank}`
      },
      [apiKeys.indicatorCoreNameKey]: {
        canOrder: true,
        orderBy: apiKeys.indicatorCoreNameKey,
        label: t('parties_latest_column_party'),
        Renderer: function PartyNameRenderer(value) {
          return <Box fontWeight={600}>{value[apiKeys.indicatorCoreNameKey]}</Box>;
        }
      },
      result: {
        canOrder: true,
        orderBy: 'result',
        label: t('parties_latest_column_result'),
        Renderer: (value) => `${value.result.toFixed(2)} %`
      },
      percentageBar: {
        canOrder: true,
        orderBy: 'result',
        label: t('parties_latest_column_percentage'),
        Renderer: PercentageBarRenderer
      }
    });
  });

  return (
    <Table
      title={`${t('parties_latest_title')} ${
        eventsStore.latestParliamentaryPartyListElection?.[apiKeys.eventNameKey]
      }`}
      loading={partiesStore.latestElectionLoading}
      rows={partiesStore.latestPartyRows}
      headCells={headCells}
      defaultPagination={10}
      rowsPerPageOptions={[10, 20, 30]}
      defaultOrderBy="rank"
      searchBarFieldIds={[apiKeys.indicatorCoreNameKey]}
      idColumn={apiKeys.indicatorCoreNameKey}
      minWidth="1000px"
      rowLinks
    />
  );
});

export default LatestElectionTable;
