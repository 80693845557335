import { Box, Button, Grid, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp as GetAppIcon, Search as SearchIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { Parser } from 'json2csv';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  exportButton: {
    padding: theme.spacing(0.25, 2)
  },
  search: {
    position: 'relative'
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1
  },
  inputRoot: {
    color: 'inherit',
    border: '1px solid',
    borderColor: theme.palette.borderColor,
    boxShadow: theme.shadows[3],
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  inputInput: {
    padding: theme.spacing('5px', 1, '5px', 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '27ch'
  },
  title: {
    textTransform: 'uppercase',
    padding: '4px 0',
    borderRadius: theme.borderRadius,
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.background.header,
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '16px',
    textAlign: 'center',
    width: '100%'
  }
}));

const ToolBar = ({
  exporter,
  searchBarFieldIds,
  title,
  setSearchFilterRegExp,
  headCells,
  exporterData,
  selectedColumns
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const exportDataInCSV = useCallback(() => {
    try {
      const parser = new Parser();
      const csv = parser.parse(
        exporterData.map((row) => {
          const filteredRow = {};
          selectedColumns.forEach((key) => {
            if (headCells[key]?.downloadable) {
              filteredRow[headCells[key].label] = row[key];
            }
          });
          return filteredRow;
        })
      );
      const fileName = Date.now() + '.csv';
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  }, [selectedColumns, exporterData, headCells]);

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      spacing={2}
      style={{ marginBottom: '8px' }}
    >
      {title && (
        <Grid item xs="auto" style={{ flexGrow: 1 }}>
          <Box textAlign="center" fontWeight={800} className={classes.title}>
            {title}
          </Box>
        </Grid>
      )}
      <Grid item xs="auto">
        {searchBarFieldIds && (
          <Box className={clsx(classes.search, 'joyride-TableSearch')}>
            <Box className={classes.searchIcon}>
              <SearchIcon />
            </Box>
            <InputBase
              placeholder={`${t('election_table_search')} ${searchBarFieldIds
                .map((searchBarFieldId) => headCells[searchBarFieldId]?.label)
                .join(', ')}`}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={(e) =>
                setSearchFilterRegExp(e.target.value ? new RegExp(e.target.value, 'i') : null)
              }
            />
          </Box>
        )}
      </Grid>
      {exporter && (
        <Grid item xs="auto">
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              onClick={exportDataInCSV}
              className={clsx(classes.exportButton, 'joyride-CSVButton')}
            >
              <GetAppIcon style={{ marginRight: '4px' }} />
              CSV
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

ToolBar.propTypes = {
  exporter: PropTypes.bool,
  searchBarFieldIds: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.node,
  setSearchFilterRegExp: PropTypes.func.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  headCells: PropTypes.object.isRequired,
  exporterData: PropTypes.array.isRequired
};

export default ToolBar;
