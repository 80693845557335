import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { view } from '@risingstack/react-easy-state';
import React, { useContext, useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import ElectionContext from '../ElectionContext';

const OverallBarChart = view(({ ...rest }) => {
  const theme = useTheme();
  const { hoverSortedResultsIndicators, showHovered, hoverInfo, parentShapeData, loading } =
    useContext(ElectionContext);

  const { data, sum } = useMemo(() => {
    if (loading) {
      return { data: [], sum: 0 };
    }
    const properties = showHovered ? hoverInfo : parentShapeData?.feature.properties;
    let sum = 0;
    const data = [
      hoverSortedResultsIndicators.reduce(
        (acc, resultsIndicator) => {
          if (
            !properties?.[resultsIndicator.root_indicator_id] ||
            properties[resultsIndicator.root_indicator_id] < 1
          ) {
            return acc;
          }
          sum += properties[resultsIndicator.root_indicator_id];
          return {
            ...acc,
            [resultsIndicator.root_indicator_id]: properties?.[resultsIndicator.root_indicator_id]
          };
        },
        { label: '' }
      )
    ];
    return { data, sum };
  }, [
    loading,
    hoverInfo,
    parentShapeData?.feature.properties,
    showHovered,
    hoverSortedResultsIndicators
  ]);

  return (
    <Box width={1} maxWidth="100%" height="20px" borderRadius="2px" overflow="hidden" {...rest}>
      {!loading ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            barCategoryGap={0}
          >
            <XAxis type="number" ticks={[sum]} hide domain={[0, sum]} />
            <YAxis dataKey="label" type="category" hide />
            {hoverSortedResultsIndicators.map((resultsIndicator) => (
              <Bar
                key={resultsIndicator.root_indicator_id}
                dataKey={resultsIndicator.root_indicator_id}
                fill={resultsIndicator.color || theme.palette.defaultIndicatorColor}
                stackId="a"
                animationBegin={0}
                animationDuration={300}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton variant="rect" width="100%" height="100%" animation="wave" />
      )}
    </Box>
  );
});

export default OverallBarChart;
