import { Box, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import React, { useEffect } from 'react';

import { useElectionData } from '../../../hooks';
import { ElectionMap, ElectionSelector } from '../../Election/components';
import ElectionContext from '../../Election/ElectionContext';

const useStyles = makeStyles(() => ({
  mapBox: {
    position: 'relative'
  }
}));

const PartyElectionMap = view(({ electionsStore, partyStore, id }) => {
  const classes = useStyles();
  const theme = useTheme();
  const electionStore = useElectionData({
    eventIdKey: `${id}EventId`,
    parentShapeIdKey: `${id}ParentShapeId`,
    rootShapeIdKey: `${id}RootShapeId`,
    rootIndicatorIdKey: `${id}RootIndicatorId`,
    paletteKey: `${id}Palette`,
    defaultRootIndicators: partyStore.party.events
  });

  useEffect(() => {
    electionsStore.stores[id] = electionStore;
  }, [electionStore, electionsStore, id]);

  return (
    <ElectionContext.Provider value={electionStore}>
      <Box mb={2} position="relative">
        {electionsStore.storesArray.length > 1 && (
          <Box position="absolute" left={-1} top={0} zIndex={2} display="flex" height={1}>
            <Button
              variant="outlined"
              onClick={() => electionsStore.deleteElection(id)}
              style={{
                minWidth: '0',
                width: '40px',
                padding: 0,
                boxShadow: 'none',
                borderRadius: `${theme.borderRadius} 0 0 ${theme.borderRadius}`
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
        )}
        <ElectionSelector restrictedIds={partyStore.eventsParticipated} paddingLeft="40px" />
      </Box>
      <Box className={classes.mapBox}>
        <ElectionMap
          showStylePanel={false}
          defaultWidth={604}
          defaultHeight={330}
          padding={{ top: 10, bottom: 40, left: 10, right: 145 }}
          styleDefaults={{
            water: false,
            parks: false,
            roads: true,
            labels: false,
            nature: false
          }}
          disableFullScreen
          compareButton={false}
          scrollZoom={false}
          showIndicatorPanel={false}
          defaultExpandedPalette={false}
        />
      </Box>
    </ElectionContext.Provider>
  );
});

export default PartyElectionMap;
