import { view } from '@risingstack/react-easy-state';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Joyride } from '../../../components';

const partySteps = [
  'PartyDetails',
  'LatestCharts',
  'PartyMaps',
  'AddElectionButton',
  'ElectionSelector',
  'PartyLineChart',
  'PartyLineChartSelector',
  'PartyTable'
];

const PartyJoyride = view(() => {
  const { t } = useTranslation();

  const steps = useMemo(
    () => [
      {
        title: t('joyride_party_title_intro'),
        content: t('joyride_party_intro'),
        placement: 'center',
        target: 'body',
        disableBeacon: true
      },
      ...partySteps.map((step) => ({
        title: t(`joyride_party_title_${step}`),
        content: t(`joyride_party_${step}`),
        target: `.joyride-${step}`,
        disableBeacon: true
      }))
    ],
    [t]
  );

  return <Joyride steps={steps} />;
});

export default PartyJoyride;
