import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCollator } from '../hooks';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiInput-underline:before': {
      margin: 'auto',
      borderRadius: theme.borderRadius,
      width: '99.7%'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  selectedOption: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
    padding: theme.spacing(0, 0, 0, 1),
    boxShadow: 'none',
    borderRadius: theme.borderRadius,
    border: '1px solid',
    borderColor: theme.palette.borderColor,
    fontSize: '15px',
    fontWeight: 500
  },
  closeButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5)
  },
  closeIcon: {
    fontSize: '18px'
  },
  select: {
    boxShadow: theme.shadows[6],
    padding: `${theme.spacing(1.5, 18, 1.5, 1.5)} !important`,
    borderRadius: theme.borderRadius,
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.borderRadius
    }
  },
  selectDisabled: {
    backgroundColor: `${theme.palette.background.default} !important`,
    cursor: 'not-allowed !important'
  },
  selectMenu: {
    overflow: 'auto',
    textOverflow: 'unset'
  },
  selectIcon: {
    display: 'none'
  },
  input: {
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.palette.borderColor,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.borderRadius
  },
  checkBox: {
    padding: theme.spacing(0, 1, 0, 0),
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.primary
    }
  },
  menuItem: {
    padding: theme.spacing(0.5, 0, 0.5, 2)
  },
  menuPaper: {
    maxHeight: 350,
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.palette.borderColor,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  changeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    borderRadius: `0 ${theme.borderRadius} ${theme.borderRadius} 0`,
    padding: theme.spacing(1.5),
    boxShadow: 'none',
    fontWeight: 600
  }
}));

const MultiSelect = ({ options, setSelected, selected, label, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const collator = useCollator();

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const changeSelected = useCallback(
    (e) => {
      setSelected(e.target.value);
    },
    [setSelected]
  );

  const cutSingle = useCallback(
    (e, id) => {
      e.stopPropagation();
      setSelected(selected.filter((selectedId) => selectedId !== id));
    },
    [selected, setSelected]
  );

  const orderedOptionKeys = useMemo(
    () => Object.keys(options).sort((a, b) => collator.compare(options[a], options[b])),
    [options, collator]
  );

  const disabled = useMemo(() => !Object.keys(options).length, [options]);

  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        value={selected}
        onChange={changeSelected}
        classes={{
          root: classes.select,
          selectMenu: classes.selectMenu,
          icon: classes.selectIcon,
          disabled: classes.selectDisabled
        }}
        disabled={disabled}
        inputProps={{ className: classes.input }}
        displayEmpty={!!label}
        renderValue={(selected) =>
          !selected.length
            ? label
            : selected.map((id) => (
                <Box key={id} className={classes.selectedOption}>
                  {options[id]}
                  <IconButton onMouseDown={(e) => cutSingle(e, id)} className={classes.closeButton}>
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </Box>
              ))
        }
        MenuProps={{
          PaperProps: {
            className: classes.menuPaper
          },
          MenuListProps: {
            style: { padding: 0 }
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }}
        {...rest}
      >
        {orderedOptionKeys.map((id) => (
          <MenuItem key={id} value={id} className={classes.menuItem}>
            <Checkbox
              checked={selected.indexOf(id) > -1}
              color="primary"
              size="small"
              className={classes.checkBox}
            />
            <ListItemText primary={options[id]} />
          </MenuItem>
        ))}
      </Select>
      <Button
        disabled={disabled}
        className={classes.changeButton}
        color="secondary"
        variant="contained"
        onClick={onOpen}
      >
        {t('select_change')}
      </Button>
    </FormControl>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  label: PropTypes.string
};

export default MultiSelect;
