export { default as EmptyGeoJson } from './emptyGeoJson.json';
export { default as LayerColors } from './layerColors';
export { default as MapStyle } from './map-style-basic-v8.json';
export const ZOOM_AMOUNT = 1;
export const ZOOM_TRANSITION_DURATION = 500;
export const countryViewport = {
  width: 1230,
  height: 500,
  latitude: 42.233,
  longitude: 42.946,
  zoom: 6.367,
  bearing: 0,
  pitch: 0
};
