import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IMGNDI, IMGRisingstackLogo } from '../images';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: theme.palette.background.paper
  },
  root: {
    padding: theme.spacing(1.5, 0),
    flexGrow: 1
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '15px',
    fontWeight: 500,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start'
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt={10} className={classes.footer}>
      <Container maxWidth="lg">
        <Grid
          container
          className={classes.root}
          alignItems="stretch"
          justify="space-between"
          spacing={1}
        >
          <Grid item xs={12} md={8}>
            <Box display="flex" alignItems="center" height={1}>
              <a target="_blank" href="https://www.ndi.org/" rel="noreferrer">
                <img src={IMGNDI} alt="IMGNDI" width="100px" />
              </a>
              <Box fontSize={15} whiteSpace="pre-wrap" fontWeight={500} ml={4}>
                {t('footer_issue')}
                <a href="mailto:ndigeorgia@ndi.org">ndigeorgia@ndi.org</a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <a
              target="_blank"
              href="https://risingstack.com/"
              rel="noreferrer"
              className={classes.link}
            >
              {t('footer_development')}
              <Box ml={1.5} display="flex">
                <img src={IMGRisingstackLogo} alt="IMGRisingstackLogo" width="160px" />
              </Box>
            </a>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
