import { store } from '@risingstack/react-easy-state';

const tutorialStore = store({
  run: false,
  runTutorial() {
    tutorialStore.run = true;
  }
});

export default tutorialStore;
