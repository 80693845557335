import { Box, Button, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import React, { useEffect } from 'react';

import { useElectionData } from '../../hooks';
import { ElectionMap, ElectionSelector, IndicatorPanel } from './components';
import ElectionContext from './ElectionContext';

const useStyles = makeStyles(() => ({
  mapBox: {
    position: 'relative'
  }
}));

const ElectionCompareElection = view(({ electionsStore, id }) => {
  const classes = useStyles();
  const theme = useTheme();
  const electionStore = useElectionData({
    eventIdKey: `${id}EventId`,
    parentShapeIdKey: `${id}ParentShapeId`,
    rootShapeIdKey: `${id}RootShapeId`,
    rootIndicatorIdKey: `${id}RootIndicatorId`,
    paletteKey: `${id}Palette`
  });

  useEffect(() => {
    electionsStore.stores[id] = electionStore;
  }, [electionStore, electionsStore, id]);

  return (
    <ElectionContext.Provider value={electionStore}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={4}>
          <IndicatorPanel
            width={1}
            height={424}
            resultSkeletonsLength={6}
            otherSkeletonsLength={12}
          />
        </Grid>
        <Grid item xs={8}>
          <Box mb={2} position="relative">
            {electionsStore.storesArray.length > 2 && (
              <Box position="absolute" left={-1} top={0} zIndex={2} display="flex" height={1}>
                <Button
                  variant="outlined"
                  onClick={() => electionsStore.deleteElection(id)}
                  style={{
                    minWidth: '0',
                    width: '40px',
                    padding: 0,
                    boxShadow: 'none',
                    borderRadius: `${theme.borderRadius} 0 0 ${theme.borderRadius}`
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
            )}
            <ElectionSelector paddingLeft="40px" />
          </Box>
          <Box className={classes.mapBox}>
            <ElectionMap
              showStylePanel={false}
              defaultWidth={816}
              defaultHeight={400}
              padding={{ top: 15, bottom: 40, left: 15, right: 155 }}
              styleDefaults={{
                water: false,
                parks: false,
                roads: true,
                labels: false,
                nature: false
              }}
              disableFullScreen
              compareButton={false}
              showIndicatorPanel={false}
              scrollZoom={false}
            />
          </Box>
        </Grid>
      </Grid>
    </ElectionContext.Provider>
  );
});

export default ElectionCompareElection;
