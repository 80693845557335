import { batch } from '@risingstack/react-easy-state';
import { useEffect } from 'react';
import { setParams, setPath } from 'react-easy-params';
import { useHistory } from 'react-router-dom';

import { tutorialStore } from '../globalStores';

function notEmpty(token) {
  return token !== '';
}

export default function useEasyParams() {
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      batch(() => {
        tutorialStore.run = false;
        const newPath = location.pathname.split('/').filter(notEmpty);
        if (newPath.length === 0) {
          setPath(['elections']);
        } else {
          setPath(newPath);
        }

        const queryTokens = location.search.slice(1).split('&').filter(notEmpty);
        const params = {};
        for (let token of queryTokens) {
          const keyValue = token.split('=');
          if (keyValue.length === 2) {
            const key = decodeURIComponent(keyValue[0]);
            const value = decodeURIComponent(keyValue[1]);
            try {
              params[key] = JSON.parse(value);
            } catch (err) {
              params[key] = value;
            }
          }
        }
        setParams(params);
      });
    });
  }, [history]);
}
