import { Box, Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { params } from 'react-easy-params';
import { useTranslation } from 'react-i18next';

import { useElectionsStore } from '../../hooks';
import { ElectionCompareJoyride, PartyLineChart, SummaryCompareTable } from './components';
import ElectionCompareElection from './ElectionCompareElection';

const defaultElection = {
  event_id: 46,
  root_shape_id: 89486
};

const ElectionCompare = view(() => {
  const { t } = useTranslation();
  const electionsStore = useElectionsStore({ defaultElection });

  return (
    <>
      <ElectionCompareJoyride />
      {(params.ids || []).map((id) => (
        <Box my={1} key={id}>
          <ElectionCompareElection electionsStore={electionsStore} id={id} />
        </Box>
      ))}
      <Box mt={2} mb={5} display="flex" justifyContent="center">
        <Button
          variant="outlined"
          onClick={electionsStore.createElection}
          className="joyride-AddElectionButton"
        >
          <AddIcon fontSize="small" />
          {t('election_compare_add_election')}
        </Button>
      </Box>
      <Box my={4}>
        <PartyLineChart
          electionsStore={electionsStore}
          width={1180}
          height={460}
          paddingX={{ left: 150, right: 190 }}
        />
      </Box>
      <SummaryCompareTable electionsStore={electionsStore} />
    </>
  );
});

export default ElectionCompare;
